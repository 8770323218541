<template>
    <v-card class="mx-auto mb-2" v-on:click="orderSelected" id="container">
        <v-container>
            <v-row align="center">
                <v-col class="pa-0">
                    <v-card-text>
                        <p class="text-h6 text--primary mb-1 gs">
                            {{ order.customer.name }}
                        </p>
                        <p class="text-h5 text--primary mb-0 gs">
                            {{ order.orderNumber }}
                        </p>
                    </v-card-text>
                </v-col>
                <v-col cols="4" class="pa-0 mt-2 d-flex justify-end">
                    <div>
                        <v-chip class="ma-2" color="primary" small outlined> {{ getOrderType }} ({{ order.items.length }} {{
                                order.items.length === 1 ? 'item' : 'items'
                        }})</v-chip>
                        <v-card-text class="pt-1 text-right">
                            <p class="mb-0">{{ getSlotDateFormatted }}</p>
                            <p class="mb-0">{{ getSlotStartFormatted }} – {{ getSlotEndFormatted }}</p>
                        </v-card-text>
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="order.status == 'COMPLETED'" cols="12" class="red text-center white--text">
                    ALREADY HANDED OVER
                </v-col>
                <v-col v-if="order.status == 'PICKING'" cols="12" class="orange text-center white--text">
                    CURRENTLY IN PICKING
                </v-col>
            </v-row>
        </v-container>
    </v-card>

</template>
  
<script>
import moment from "moment";

export default {
    name: "OrderListItem",
    props: ["order"],
    computed: {
        getSlotDateFormatted() {
            return moment(this.order.collectionSlotStart).format("Do MMM");
        },
        getSlotStartFormatted() {
            return moment(this.order.collectionSlotStart).format("HH:mm");
        },
        getSlotEndFormatted() {
            return moment(this.order.collectionSlotEnd).format("HH:mm");
        },
        getOrderType(){
            switch(this.order.type){
                case "DELIVERY":
                    return "Delivery";
                case "COLLECTION":
                    return "Grocery Collection";
                case "ENTERTAINING COLLECTION":
                    return "Entertaining Collection";
                default:
                    return this.order.type;
            }
        }
    },
    methods: {
        orderSelected() {
            this.$store.commit("setActiveOrder", this.order);
            this.$router.push("/customer-handover/order-review");
        },
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#container {
    text-align: left;
}
.gs {
    font-family: Gill Sans, sans-serif !important;
}
</style>
  