<template>
<div>
    <div id="newBarcode" v-if="newBarcodeScreen">
        <img src="@/assets/img/ent-scan.png" width="100%">
        <GillTitle title="Scan or key a new barcode to add to this line." />
    </div>

    <div v-if="!newBarcodeScreen">
        <v-text-field hide-details outlined v-model="lineNumberEntry" type="number" class="mb-5" id="lineNumberEntry"/>
        <GillTitle v-if="activeItem==null && !showUnknownLine" title="Scan/key a barcode, or key line number in the box above to perform assortment maintenance." />
        <GillTitle v-if="showUnknownLine" title="The barcode you scanned is unknown. Please enter the associated line number in the box above." />
        <div v-if="activeItem!=null">
            <Item v-bind:lineNumber="activeItem.lineNumber" />
            <v-row justify="center" class="mt-3">
                <v-col cols="10">
                    <v-row class="barc-row">
                        <v-col cols="9" style="padding-bottom: 0">
                            <p class="barc-header">Barcode</p>
                        </v-col>
                        <v-col cols="3" style="padding-bottom: 0">
                            <p class="barc-header">Qty</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="10" style="padding-top: 0;">
                    <v-row v-for="barcode in barcodes" :key="barcode[0]" class="barc-row">
                        <v-col cols="9">
                            <v-text-field outlined hide-details class="barc-field" v-model="barcode[0]" />
                        </v-col>
                        <v-col cols="3">
                            <v-text-field outlined hide-details class="barc-field" v-model="barcode[1]" />
                        </v-col>
                    </v-row>
                    <!--<v-row class="barc-row">
                        <v-col cols="12">
                            <BSMButton v-on:click="newBarcodeScreenPlease()" text="+ Add New Barcode" color="#4873D1" textColor="#000000" />
                        </v-col>
                    </v-row>-->
                </v-col>
            </v-row>

            <!--<v-row>
                <v-col cols="12">
                    <BSMButton v-on:click="saveChanges()" text="Save Changes" color="#F0D909" textColor="#000000" />
                </v-col>
            </v-row>-->

            <v-row justify="center" align="center" class="mt-12" style="background-color: #FFFF00;">
                <h1 id="recommended-location" v-if="bestLocation != null">Recommended location: <b>{{bestLocation}}</b></h1>
            </v-row>
        </div>
    </div>
    <WarningDialog v-bind:dialog="showWarningDialog" v-on:closeDialog="showWarningDialog=false" title="Alert" v-bind:text="warningText"/>
</div>
</template>

<style>
    #recommended-location {
        font-size: 4vh;
    }
</style>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import Item from "@/components/Item.vue"
import GillTitle from "@/components/GillTitle.vue"
import WarningDialog from "@/components/WarningDialog.vue"
//import BSMButton from "@/components/BSMButton.vue"

import utils from "@/assets/scripts/utils.js"

export default {
    name: 'Assortment',
    data: () => {
        return {
            activeItem: null,
            quantity: 1,
            barcodes: [],
            showWarningDialog: false,
            bestLocation: null,
            newBarcodeScreen: false,
            lineNumberEntry: "",
            tempBarcode: "",
            showUnknownLine: false,
            warningText: ""
        }
    },
    components: {
        Item,
        GillTitle,
        WarningDialog,
        //BSMButton
    },
    beforeMount() {
        this.$store.commit("setTitle", "Assortment")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Scan/key an item")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    watch: {
        lineNumberEntry: function(){
            //Is line number valid?
            var item = utils.itemLookupService(this.lineNumberEntry)
            if(item != null){
                const barcodes = utils.getBarcodesForLineNumber(item.lineNumber)
                
                let replacementBarcodes = []
                barcodes.forEach((barcode) => {
                    replacementBarcodes.push([barcode.barcode, barcode.quantity])
                })

                if(this.tempBarcode != ""){
                    this.barcodes.push([this.tempBarcode, 1])
                    this.tempBarcode = ""
                    this.showUnknownLine = false
                }

                this.activeItem = {
                    "lineNumber": this.lineNumberEntry
                }

                this.barcodes = replacementBarcodes
            }else{
                this.barcodes = []
                this.activeItem = null
            }
        }
    },
    methods: {
        newBarcodeScreenPlease: function(){
            this.newBarcodeScreen = true
            this.$store.commit("setActionText", "Scan/key an item")
        },
        saveChanges: function(){
            this.$store.dispatch("updateBarcodes", {
                "lineNumber": this.activeItem.lineNumber,
                "barcodes": this.barcodes
            })
            this.activeItem = null
            this.barcodes = null
            this.lineNumberEntry = ""
        },
        scanned: function(e) {
            if(this.$route.name == "Assortment"){
                if(this.activeItem == null){
                    var item = utils.barcodeLookupService(e.detail.value)
                    if(item.lineNumber == null){
                        //Barcode doesn't exist
                        this.tempBarcode = e.detail.value
                        this.showUnknownLine = true
                    }else{
                        //Item was found
                        this.showUnknownLine = false
                        this.$store.commit("setActionText", "")
                        this.activeItem = item
                        this.lineNumberEntry = item.lineNumber
                        this.barcodes = utils.getBarcodesForLineNumber(item.lineNumber)
                    }
                }else{
                    if(this.newBarcodeScreen){
                        this.$store.commit("setActionText", "Scan/key an item")
                        //Is this barcode already konwn?
                        let found = false
                        this.barcodes.forEach((knownBarcode) => {
                            if(knownBarcode.barcode == e.detail.value){
                                //Barcode already known
                                found = true
                            }
                        })
                        if(found){
                            this.warningText = "This barcode is already associated with this line. Please check and try again."
                            this.showWarningDialog = true
                        }else{
                            this.barcodes.push([e.detail.value, 1])
                            this.newBarcodeScreen = false
                        }
                    }else{
                        //Barcode scan not allowed!
                        this.warningText = "To add a new barcode to this line, press the 'Add new barcode' button first or press 'Back' to modify a different line."
                        this.showWarningDialog = true
                    }
                }
            }
        }
    },
    computed: {

    }
}
</script>
