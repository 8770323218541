<template>
    <div>
        <v-row class="align-left mx-2">
            <h1>Pending Orders</h1>
            <p>This displays orders which are still to be collected, including those before their collection slot, excluding overdue orders.</p>
        </v-row>
        <!--Items begin-->
        <!--v-on:click="findSub(item)" (maybe next year)-->
        <v-card v-for="order in pending" v-bind:key="order.orderNumber" class="mb-6 mt-6 py-8 px-2" v-on:click="confirmDispatch(order.orderNumber)">
            <v-row justify="center" class="d-flex">
                <v-col cols="9" class="pb-0 pl-6 pt-0 align-left" align-self="center">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-2 pr-0 pl-0">
                            <p class="mb-title item-title text-h5">{{ order.orderNumber }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                            <strong class="text-h5">{{ order.customer.name }}</strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3" class="pb-0 pr-6 pt-0 align-left">
                    <strong>Collect by {{ getFormattedDate(order.collectionSlotEnd) }}</strong>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>
    
<script>
import moment from 'moment'

import axios from 'axios'
import vars from "@/assets/scripts/vars.js"

export default {
    name: 'PendingOrders',
    data: () => {
        return {
            pending: [],
        }
    },
    components: {
        
    },
    beforeMount() {
        this.$store.commit("setTitle", "Pending Orders")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "")
    },
    mounted() {
        this.$store.commit("setLoading", true)
        this.getPending()
    },
    methods: {
        getFormattedDate(date){
            return moment(date).format("Do HH:mm")
        },
        getItemTitle: function(lineNumber){
            return this.$store.state.stock[lineNumber].title
        },
        getPending() {
            axios({
                "method": "GET",
                "url": vars.apiURL + "orders/pending/" + this.$store.state.user.branch,
                "headers": {
                    "Authorization": "Bearer " + this.$store.state.user.access_token
                }
            }).then((data) => {
                this.pending = data.data.filter(order => order.type === "ENTERTAINING COLLECTION")
                this.$store.commit("setLoading", false)

            }).catch((error) => {
                console.log(error)
            })
        },
        confirmDispatch(orderNumber) {
            //this.$store.commit("setLoading", true)
            //Get order
            let order = this.$store.state.orders[orderNumber]

            // Now load order review screen
            this.$store.commit("setActiveOrder", order);
            this.$router.push({ name: 'OrderReview' })
        }
    }
}
</script>
    