<template>
    <div>
        <GillTitle title="Dispatch order?" />
        <br><v-text-field label="Note to picker" solo v-model="pickerNote" />
        <v-btn v-on:click="dispatchToRemotePick(true)"
            style="margin-top: 1rem; margin-bottom: 1rem; width: 100%; padding-top: 2rem; padding-bottom: 2rem;"><v-icon
                style="margin-right: 1rem;" large color="black">mdi-send</v-icon>Dispatch to remote pick</v-btn><br />
        <v-btn v-on:click="dispatchToRemotePick(false)"
            style="margin-top: 1rem; margin-bottom: 1rem; width: 100%; padding-top: 2rem; padding-bottom: 2rem;"><v-icon
                style="margin-right: 1rem;" large color="black">mdi-cursor-pointer</v-icon>Pick now</v-btn><br />

        <WarningDialog v-bind:dialog="showDispatchFailed" v-on:closeDialog="showDispatchFailed=false" title="Error"
            v-bind:text="warningDialogMessage" />
    </div>
</template>
    
<style>
#recommended-location {
    font-size: 4vh;
}
</style>
    
<script>
// @ is an alias to /src

import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import GillTitle from "@/components/GillTitle.vue"
import WarningDialog from "@/components/WarningDialog.vue"
import axios from "axios"

export default {
    name: 'Dispatch',
    data: () => {
        return {
            pickerNote: "",
            showDispatchFailed: false,
            warningDialogMessage: ""
        }
    },
    components: {
        GillTitle,
        WarningDialog
    },
    beforeMount() {
        this.$store.commit("setTitle", "Customer Collection")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Review order contents")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    watch: {

    },
    methods: {
        scanned: function (e) {
            console.log(e.detail)
        },
        dispatchToRemotePick: function (active) {
            this.$store.commit("setLoading", true)
            //Send the PickList to the server
            axios({
                "url": vars.apiURL + "orders/" + this.$store.state.activeOrder.orderNumber + "/dispatch",
                "method": "POST",
                "data": {
                    "branch": this.$store.state.user.branch,
                    "pickerNote": this.pickerNote,
                    "exclusions": {},
                    "active": true
                },
                "headers": {
                    "Authorization": "Bearer " + this.$store.state.user.access_token
                }
            }).then((res) => {
                if (res.status == 201) {
                    this.$store.commit("setActiveOrder", null)
                    if (active) {
                        this.$router.push("/customer-handover")
                    } else {
                        console.log("Setting to " + res.data.pickListID)
                        this.$store.commit("setActivePickList", res.data.pickListID)

                        //Manually trigger Pick Service first
                        axios.get(vars.apiURL + "picklist/all/" + this.$store.state.user.branch, {
                            "headers": {
                                "Authorization": "Bearer " + this.$store.state.user.access_token
                            }
                        }).then((res) => {
                            this.$store.commit("setActivePicks", res.data)
                            this.$router.push("/remote-pick/pick-picklist")
                        }).catch((err) => {
                            console.log(err)
                        })
                    }
                } else {
                    this.showDispatchFailed = true
                }
            }).catch((err) => {
                if (typeof err.response != "undefined" && err.response.data.error == "PickList already dispatched for this order") {
                    this.warningDialogMessage = "PickList already dispatched for this order."
                } else {
                    this.warningDialogMessage = "Failed to dispatch this order to picking."
                }
                this.showDispatchFailed = true
            }).finally(() => {
                this.$store.commit("setLoading", false)
            })
        },
    },
    computed: {

    }
}
</script>
    