<template>
    <v-row class="fill-height">
        <v-col cols="4" align-self="center">
            <p id="absolute-unit">UNITS:</p>
        </v-col>
        <v-col cols="3" align-self="center" class="fill-height">
            <input type="number" id="qtyInput" min="1" max="99" v-model="dataQuantity"/>
        </v-col>
        <v-col cols="5">
            <v-row>
                <v-col cols="12">
                    <v-btn id="greenBtn" v-on:click="adjustQty(1)" class="pt-6 pb-6 qtyBtn" tile large block><span id="btnText">+</span></v-btn>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-btn id="redBtn" v-on:click="adjustQty(-1)" class="pt-6 pb-6 qtyBtn" tile large block><span id="btnText">-</span></v-btn>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name: 'BSMQuantity',
    props: {
        quantity: Number,
    },
    data: function(){
        return {
            dataQuantity: 0
        }
    },
    methods: {
        adjustQty: function(amount){
            this.dataQuantity += amount
        }
    },
    created(){
        this.dataQuantity = this.quantity
    },
    watch: {
        dataQuantity: function(){
            this.$emit("setQty", this.dataQuantity)
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.qtyBtn {
    font-size: 10vh;
}
#greenBtn {
    background-color: #06EB01;
}
#redBtn {
    background-color: #EB0101;
}
#absolute-unit {
    font-size: 3vh;
}
#qtyInput {
    height: 100%;
    width: 100%;
    background-color: #FAFAFA;
    font-size: 10vh;
    text-align: center;
}
</style>
