<template>
    <v-btn id="btn" v-on:click="$emit('click')" class="pt-6 pb-6" tile v-bind:style="'background-color:'+color+';'" large block><span id="btnText" v-bind:style="'color:'+textColor+';'">{{text}}</span></v-btn>
</template>

<script>
export default {
    name: 'BSMButton',
    props: {
        text: String,
        color: String,
        textColor: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#btnText {
    font-size: 3vh;
    color: #FFFFFF;
}
</style>
