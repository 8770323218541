<template>
    <v-btn id="btn" v-bind:disabled="disabled" v-bind:loading="loading" block v-on:click="$emit('login')" v-bind:style="'background-color:'+bgColor+';color:'+color+';'">{{text}}</v-btn>
</template>

<script>
export default {
    name: 'WrButton',
    props: {
        text: String,
        bgColor: {
            default: "#5C8018",
            type: String
        },
        color: {
            default: "#FFFFFF",
            type: String
        },
        disabled: {
            default: false,
            type: Boolean
        },
        loading: {
            default: false,
            type: Boolean
        }
    }
}
</script>

<style scoped>
    #btn {
        padding-top: 5vh;
        padding-bottom: 5vh;
        font-size: 3em;
    }
</style>
