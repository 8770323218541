<template>
<div class="text-center">
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                Select a location
            </v-card-title>

            <v-card-text>
                <v-list two-line>
                    <div v-for="(stockRecord, index) in item.stockRecords" v-bind:key="stockRecord.locationValue">
                        <v-list-item two-line v-on:click="selectLocation(stockRecord)">
                            <v-list-item-content>
                                <v-list-item-title class="location-header">{{stockRecord.locationValue}}</v-list-item-title>
                                <v-list-item-subtitle class="location-subtitle">{{stockRecord.quantity}} units</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider v-if="index != item.stockRecords.length - 1" v-bind:key="index + '-div'" />
                    </div>
                </v-list>
            </v-card-text>

        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'LocationSelection',
    data: function(){
        return {
            stock: {}
        }
    },
    props: {
        dialog: Boolean,
        item: Object
    },
    created(){
        
    },
    methods: {
        selectLocation: function(location){
            var evnt = new CustomEvent("locationSelected", {
                bubbles: false,
                cancelable: false,
                detail: {
                    item: this.item,
                    location: location
                }
            })
            document.dispatchEvent(evnt)
        }
    },
    watch: {
        dialog: function(val) {
            if (val) {
                var audio = new Audio("/assets/audio/warning.mp3")
                audio.play()
                window.navigator.vibrate(200)
            } else {
                this.$emit("closeDialog", 1)
            }
        }
    }
}
</script>

<style scoped>
.location-header {
    font-size: 150%;
}

.location-subtitle {
    font-size: 150%;
}
</style>
