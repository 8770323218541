<template>
  <div>
    <p class="align-left">Enter order number or customer surname</p>
    <v-text-field hide-details outlined class="my-2" v-model="searchCriterion"></v-text-field>

    <OrderList>
      <OrderListItem v-for="order in results" :key="order.item.orderNumber" :order="order.item" />
    </OrderList>
  </div>
</template>

<style>
#recommended-location {
  font-size: 4vh;
}
</style>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import Fuse from "fuse.js";
import debounce from "debounce";

import OrderList from "@/components/CustomerHandover/OrderList.vue";
import OrderListItem from "@/components/CustomerHandover/OrderListItem.vue";

const fuseOptions = {
  keys: ["orderNumber", "customer.name"],
};

const ENT_COLLECTION = "ENTERTAINING COLLECTION";

export default {
  name: "CustomerHandover",
  data: () => {
    return {
      searchCriterion: "",
      results: [],
      orders: [],
    };
  },
  components: {
    OrderList,
    OrderListItem,
  },
  beforeMount() {
    this.$store.commit("setTitle", "Customer Collection");
    this.$store.commit("enableNavbar", "true");
    this.$store.commit("setActionText", "Enter order number");
  },
  created() {
    document.addEventListener("keyInputXd", this.scanned);

    // Change orders to array
    this.orders = [];
    for (let orderNumber of Object.keys(this.$store.state.orders)) {
      let order = this.$store.state.orders[orderNumber];
      order.orderNumber = orderNumber;
      order.collectionSlotStart = new Date(order.collectionSlotStart);
      order.collectionSlotEnd = new Date(order.collectionSlotEnd);
      this.orders.push(order);
    }

    // Entertaining Collections first
    this.orders.sort((a, b) => {
      // sort by collection time first, and then bump entertaining collections to the top
      if (a.collectionSlotStart < b.collectionSlotStart) return -1;
      if (a.collectionSlotStart > b.collectionSlotStart) return 1;
      if (a.collectionSlotStart === b.collectionSlotStart) {
        if (a.type === ENT_COLLECTION && b.entertainingCollection !== ENT_COLLECTION) return -1;
        if (a.type !== ENT_COLLECTION && b.type === ENT_COLLECTION) return 1;
      }
    });

    this.fuse = new Fuse(this.orders, fuseOptions);
  },
  destroyed() {
    document.removeEventListener("keyInputXd", this.scanned);
  },
  watch: {
    searchCriterion: debounce(function (value) {
      this.results = this.fuse.search(value);
    }, 400),
  },
  methods: {
    scanned: function (e) {
      console.log(e.detail);
    },
    // updateCriterion: function (value) {
    //     // this.searchCriterion = value;
    //     // this.results = this.fuse.search(value);
    // },
  },
  computed: {},
};
</script>
