<template>
<div class="text-center">
    <v-dialog v-model="dialog">
        <v-card>
            <v-card-title class="text-h5 grey lighten-2">
                {{title}}
            </v-card-title>

            <v-card-text>
                {{text}}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialog=false">
                    OK
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>
</template>

<script>
export default {
    name: 'WarningDialog',
    props: {
        title: String,
        text: String,
        dialog: Boolean
    },
    watch: {
        dialog: function(val){
            if(val){
                var audio = new Audio("/assets/audio/warning.mp3")
                audio.play()
                window.navigator.vibrate(200)
            }else{
                this.$emit("closeDialog", 1)
            }
        }
    }
}
</script>

<style scoped>
#btn {
    padding-top: 5vh;
    padding-bottom: 5vh;
    font-size: 3em;
}
</style>
