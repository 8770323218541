<template>
<div>
    <FunctionHelp header="Status gives you a live overview of key figures for the selected period." />

    <v-row>
        <v-col cols="12">
            <v-card>
                <v-row>
                    <v-col cols="12" class="pb-0">
                        <h1>20th December 2021</h1>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h2>Items</h2>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="3 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Expected</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="3 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Received</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="3 mr-0" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Remaining</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">0</p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h2>Orders</h2>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="2 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Placed</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="2 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Ready</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="2 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Picked</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="2 mr-0" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Remaining</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <h2>Sales</h2>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="3 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Estimated</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">£151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="3 mr-6" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Items Sold</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                    <v-col cols="3 mr-0" class="stat-col">
                        <v-row justify="center">
                            <!-- Header -->
                            <b><p class="mb-0">Transaction Count</p></b>
                        </v-row>
                        <v-row justify="center">
                            <!-- Value -->
                            <p class="mb-0">151</p>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </v-col>
    </v-row>
</div>
</template>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import FunctionHelp from "@/components/FunctionHelp.vue"

export default {
    name: 'Status',
    data: () => {
        return {
            partnerNumber: null,
            loadLoginButton: false,
            errorDialogText: null
        }
    },
    components: {
        FunctionHelp
    },
    beforeMount() {
        this.$store.commit("setTitle", "Operation Status")
        this.$store.commit("enableNavbar", "true")
        //<!-- Scan/key a location to add to list -->
        this.$store.commit("setActionText", "")
    },
    methods: {
        logOff: function() {
            this.$store.dispatch("logOff")
            this.$router.push("/")
        }
    },
    computed: {
        disableLoginButton: function() {
            return this.partnerNumber == null || this.loadLoginButton || this.partnerNumber.length != 8
        },
        welcomeTitle: function() {
            return 'Hey, ' + this.$store.state.partnerName.split(" ")[0] + '!'
        },
        logoffText: function() {
            return 'Log off ' + this.$store.state.partnerName
        }
    }
}
</script>

<style scoped>
    .stat-col {
        background-color: #888888;
        margin-bottom: 1rem;
        border-radius: 0.25vh;
    }
</style>
