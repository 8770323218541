<template>
  <div id="bar">
      <v-row>
          <v-col cols="4" class="pt-0" v-on:click="back()">
            <span class="navbar-button-text">Back</span>
          </v-col>
          <v-col cols="4" class="">
            <div v-if="getPendingChanges > 0 && !isCommunicating"><v-icon color="white" class="navbar-button-text my-auto">mdi-sync</v-icon> {{ getPendingChanges }}</div>
            <div v-if="getPendingChanges > 0 && isCommunicating"><v-icon color="white" class="navbar-button-text my-auto spin">mdi-sync</v-icon> {{ getPendingChanges }}</div>
          </v-col>
          <v-col cols="4" class="pt-0" v-on:click="key()">
            <span class="navbar-button-text">Key</span>
          </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  computed: {
    getPendingChanges: function(){
      return this.$store.state.pendingChanges
    }
  },
  data: function(){
    return {
      isCommunicating: false,
      previousCount: 0
    }
  },
  mounted(){
    setInterval(this.checkIsCommunicating, 1000)
  },
  methods: {
      checkIsCommunicating: function(){
          if(this.$store.state.pendingChanges != this.previousCount){
              this.previousCount = this.$store.state.pendingChanges
              this.isCommunicating = true
          }else{
              this.isCommunicating = false
          }
      },
      back: function(){
          this.$router.go(-1)
      },
      key: function(){
          var keyInput = prompt("Key barcode")
          if(keyInput != null && keyInput != ""){
              var evnt = new CustomEvent("keyInputXd", {
                  bubbles: false,
                  cancelable: false,
                  detail: {
                      value: keyInput
                  }
              })
              document.dispatchEvent(evnt)
          }
      },
      mounted() {
      }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bar{
    background-color: #878787;
    color: #FFFFFF;
    font-family: 'Gill Sans', sans-serif;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 1.2rem;
    margin-top: 0px;
}
.navbar-button-text {
    font-size: 5vh;
}

@keyframes rotate {
    100% {
        transform: rotate(-1turn);
    }
}
.spin{
   animation: rotate 2s linear infinite;
}
</style>
