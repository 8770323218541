<template>
<div>
    <v-row justify="center">
        <v-col cols="12" class="pl-1 pr-1" justify="center">
            <p v-if="$store.state.activePicks.filter((pick) => pick.active).length == 0">No live picks.</p>
            <Pick v-for="pick in $store.state.activePicks.filter((pick) => pick.active)" v-on:click="clickPick(pick)" v-bind:pick="pick" v-bind:key="pick.orderNumber" />
        </v-col>
    </v-row>

</div>
</template>

<script>
// @ is an alias to /src
import Pick from "@/components/Pick.vue"

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

export default {
    name: 'RemotePick',
    data: () => {
        return {
            partnerNumber: null,
            loadLoginButton: false,
            errorDialogText: null
        }
    },
    components: {
        Pick
    },
    beforeMount() {
        this.$store.commit("setTitle", "Live Picks")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Select a PickList")
    },
    methods: {
        clickPick: function(pick){
            this.$store.commit("setActivePickList", pick.pickListID)
            this.$router.push("/remote-pick/pick-picklist")
        }
    },
    //v-on:click="navigate('remote-pick')"
    computed: {
    }
}
</script>
