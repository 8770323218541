<template>
<div>
    <div id="newLocationScreen" v-if="newLocationScreen">
        <img src="@/assets/img/ent-loc-scan.png" width="100%">
        <GillTitle title="Scan or key a location barcode." />
    </div>

    <div v-if="!newLocationScreen">
        <v-text-field hide-details outlined v-model="lineNumberEntry" type="number" class="mb-5" id="lineNumberEntry"/>
        <GillTitle v-if="activeItem==null && !showUnknownLine" title="Scan/key a barcode, or key line number in the box above to manage stock estimates." />
        <GillTitle v-if="showUnknownLine" title="The barcode you scanned is unknown. Please enter the associated line number in the box above." />
        <div v-if="activeItem!=null">
            <Item v-bind:lineNumber="activeItem.lineNumber" />
            <v-row justify="center" class="mt-3">
                <v-col cols="10">
                    <v-row class="barc-row">
                        <v-col cols="8" style="padding-bottom: 0">
                            <p class="barc-header">Location</p>
                        </v-col>
                        <v-col cols="2" style="padding-bottom: 0">
                            <p class="barc-header">Qty</p>
                        </v-col>
                        <v-col cols="2" style="padding-bottom: 0">
                            <p class="barc-header">Chg</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="10" style="padding-top: 0;">
                    <v-row v-for="location in Object.keys(stock)" :key="location" class="barc-row">
                        <v-col cols="9">
                            <p style="font-size: 200%;">{{location}}</p>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field outlined hide-details class="barc-field" type="number" v-model="stock[location]" />
                        </v-col>
                    </v-row>
                    <v-row class="barc-row">
                        <v-col cols="12">
                            <BSMButton v-on:click="newLocationScreenPlease()" text="+ Add New Location" color="#4873D1" textColor="#000000" />
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <BSMButton v-on:click="saveChanges()" text="Save Changes" color="#F0D909" textColor="#000000" />
                </v-col>
            </v-row>
        </div>
    </div>
    <WarningDialog v-bind:dialog="showWarningDialog" v-on:closeDialog="showWarningDialog=false" title="Alert" v-bind:text="warningText"/>
</div>
</template>

<style>
    #recommended-location {
        font-size: 4vh;
    }
</style>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import Item from "@/components/Item.vue"
import GillTitle from "@/components/GillTitle.vue"
import WarningDialog from "@/components/WarningDialog.vue"
import BSMButton from "@/components/BSMButton.vue"

import utils from "@/assets/scripts/utils.js"

export default {
    name: 'Stock',
    data: () => {
        return {
            activeItem: null,
            quantity: 1,
            showWarningDialog: false,
            bestLocation: null,
            lineNumberEntry: "",
            tempBarcode: "",
            showUnknownLine: false,
            warningText: "",
            stock: [],
            newLocationScreen: false
        }
    },
    components: {
        Item,
        GillTitle,
        WarningDialog,
        BSMButton
    },
    beforeMount() {
        this.$store.commit("setTitle", "Stock")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Scan/key an item")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    watch: {
        lineNumberEntry: function(){
            //Is line number valid?
            var item = utils.itemLookupService(this.lineNumberEntry)
            if(item != null){
                this.activeItem = {
                    "lineNumber": this.lineNumberEntry
                }
                //Get stock estimates
                if(Object.keys(this.$store.state.stock).includes(item.lineNumber)){
                    this.stock = this.$store.state.stock[item.lineNumber]
                }else{
                    this.stock = {}
                }
            }else{
                this.activeItem = null
            }
        }
    },
    methods: {
        newLocationScreenPlease: function(){
            this.newLocationScreen = true
        },
        saveChanges: function(){
            this.$store.dispatch("setLocationStock", {
                "lineNumber": this.activeItem.lineNumber,
                "stock": this.stock
            })
            this.activeItem = null
            this.lineNumberEntry = ""
        },
        scanned: function(e) {
            if(this.$route.name == "Stock"){
                if(this.newLocationScreen){
                    //Add new location
                    if(e.detail.value.length == 5){
                        //Location, location!
                        //Does the location already exist?
                        if(Object.keys(this.stock).includes(e.detail.value)){
                            //Already exists
                            this.warningText = "This location already has stock assigned to it."
                            this.showWarningDialog = true
                        }else{
                            this.stock[e.detail.value] = 0
                            this.newLocationScreen = false
                        }
                    }
                }else{
                    var item = utils.barcodeLookupService(e.detail.value)
                    if(item.lineNumber == null){
                        //Barcode doesn't exist
                        this.warningText = "Barcode not found"
                        this.showWarningDialog = true
                    }else{
                        //Item was found
                        this.$store.commit("setActionText", "")
                        this.activeItem = item
                        this.lineNumberEntry = item.lineNumber
                    }
                }
            }
        }
    },
    computed: {

    }
}
</script>
