<template>
    <div>
        <v-row justify="center">
            <v-col cols="11">
                <p style="text-align:left;">{{header}}</p>
                <h2 style="text-align:left;">{{action}}</h2>
            </v-col>
        </v-row>
    </div>
</template>

<script>

export default {
    name: 'FunctionHelp',
    props: {
        header: String,
        action: String
    }
}
</script>
