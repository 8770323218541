<template>
    <v-card id="btn" class="mb-5 mt-5 py-5 px-2">
        <v-row justify="center" class="d-flex" v-on:click="toggleOptions()">
            <v-col cols="3" class="pb-0 pt-0 pl-3" align-self="center">
                <v-img
                    v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + pickListItem.lineNumber.toString().padStart(6, '000000') + '_BP_11.jpg'" />
            </v-col>
            <v-col cols="6" class="pb-0 pr-0 pt-0" align-self="center">
                <v-row>
                    <v-col cols="12" class="pb-0 pt-2 pr-0 pl-0">
                        <p class="mb-title item-title">{{ item.title }}</p>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                        <strong class="mb-ln">{{ pickListItem.lineNumber }}</strong>
                    </v-col>
                </v-row>
                <v-row class="mt-0">
                    <v-col cols="12" class="pt-0 pb-1 mt-2 pr-0 pl-0">
                        <small class="mb-desc">{{ pickListItem.pickedQty }}/{{ pickListItem.quantity }} picked</small>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="3" align-self="center" style="text-align:center;" class="fill-height pt-0 pb-0">
                <div class="item-location fill-height">
                    <p class="item-location mt-1 mb-1" v-on:click="selectLocation()">{{ getLocation() }}</p>
                </div>
                <div class="item-picked d-flex fill-height">
                    <v-img v-if="(pickListItem.pickedQty != pickListItem.quantity)"
                        src="/assets/img/mode-icons/pending-pick.png" style="border: solid 1px #000000;" />
                    <v-img v-if="(pickListItem.pickedQty == pickListItem.quantity)"
                        src="/assets/img/mode-icons/picked.png" style="border: solid 1px #000000;" />
                </div>
            </v-col>
            <LocationSelection :dialog="locationSelection" v-bind:item="item" />
        </v-row>
        <PickItemOptions :pickListItem="pickListItem" :location="getLocation()"
            v-if="activePickListItem.lineNumber == pickListItem.lineNumber"
            :disable="disablePickList" :pickFunction="pick" :pickList="pickList" />

    </v-card>
</template>

<script>
import LocationSelection from "@/components/LocationSelection.vue"

import PickItemOptions from '@/components/PickItemOptions.vue'

import utils from "@/assets/scripts/utils.js"

/*
        imageURL: String,
        title: String,
        lineNumber: String,
        picked: Number,
        total: Number,
        mass: String,
        identifier: String*/

export default {
    name: 'PickItem',
    props: {
        pickListItem: Object,
        pickList: {}
    },
    computed: {
        disablePickList: function(){
            if(this.pickListItem.pickedQty >= this.pickListItem.quantity){
                return true
            }
            if(this.getLocation() == "NONE"){
                return true
            }
            return false
        },
        activePickListItem: function () {
            return this.$store.state.activePickListItem
        },
        item: function () {
            return this.$store.state.stock[this.pickListItem.lineNumber]
        },
        btnIcon: function () {
            return "/assets/img/mode-icons/" + this.icon + ".png"
        },
        name: function () {
            if (this.mass != "N/A" && this.mass != null) {
                return this.title + " (" + this.mass + ")"
            } else {
                return this.title
            }
        },
        availableQty: function () {
            let total = 0
            this.item.stockRecords.forEach((stockRecord) => {
                total += stockRecord.quantity
            })
            return total
        }
    },
    components: {
        LocationSelection,
        PickItemOptions
    },
    data: function () {
        return {
            enableOptions: false,
            callCount: 0,
            locationSelection: false,
            customLocation: ""
        }
    },
    methods: {
        selectLocation() {
            if (this.availableQty > 0 && this.getLocation() != "?") {
                this.locationSelection = true
            }
        },
        locationSelected(e) {
            this.locationSelection = false
            let location = e.detail.location
            let lineNumber = e.detail.item.lineNumber

            if (lineNumber == this.pickListItem.lineNumber) {
                this.customLocation = location.locationValue
            }
        },
        getLocation() {
            if (Object.keys(this.$store.state.stock).includes((this.pickListItem.lineNumber).toString())) {
                if (this.customLocation == "") {
                    //Exists in stock database
                    let largestLocation = "NONE"
                    let largestEstimate = 0
                    this.item.stockRecords.forEach((stockRecord) => {
                        if (stockRecord.quantity > largestEstimate) {
                            largestEstimate = stockRecord.quantity
                            largestLocation = stockRecord.locationValue
                        }
                    })
                    return largestLocation
                } else {
                    return this.customLocation
                }
            } else {
                this.stock = {}
                return "NONE"
            }
        },
        toggleOptions: function () {
            if (this.activePickListItem.lineNumber == this.item.lineNumber || this.getLocation() == "NONE" || this.pickListItem.pickedQty >= this.pickListItem.quantity) {
                this.$store.commit("setActivePickListItem", 0)
            } else {
                this.$store.commit("setActivePickListItem", this.item)
            }
        },
        scanned: function (e) {
            //CHECK FOR THE MULTIPLE SCAN BUG

            let barcode = e.detail.value
            //Check if any items match barcode

            let barcodeLookup = utils.barcodeLookupService(barcode)
            if (barcodeLookup != null) {
                var lineNumber = barcodeLookup.lineNumber
                var qty = barcodeLookup.quantity

                if (lineNumber == this.pickListItem.lineNumber && this.pickListItem.pickedQty < this.pickListItem.quantity) {
                    //This item has been scanned!
                    this.pick(this.pickListItem, qty, this.getLocation(), this.pickList)
                }
            }
        },
        pick: function (pickListItem, quantity, location, pickList) {
            this.$store.dispatch("pickItemFromPickList", {
                "item": pickListItem,
                "quantity": quantity,
                "location": location,
                "pickList": pickList
            })
            if (pickListItem.pickedQty >= pickListItem.quantity || this.getLocation() == "NONE") {
                this.$store.commit("setActivePickListItem", 0)
            }
        }
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
        document.addEventListener('locationSelected', this.locationSelected)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
        document.removeEventListener('locationSelected', this.locationSelected)
    }
}
</script>

<style scoped>
.center {
    text-align: center;
}

#btn {
    text-align: left;
    font-size: 3vh;
    background-color: #FF9900;
}

.item-location {
    background-color: #45434E;
    color: #FFFFFF;
    font-size: 3vh;
}

.item-picked {
    background-color: #066CFA;
}

.mb-title {
    font-size: 3vh;
    line-height: 4vh;
}

.mb-ln {
    font-size: 2.5vh;
}

.mb-desc {
    font-size: 3vh;
}

.icon-col {
    background-color: #FFFFFF;
}

#mins-elapsed {
    font-size: 8vh;
    line-height: 10vh;
}

.mins-container {
    max-height: 100px;
}

.pick-time {
    background-color: #4B9728;
}

.pick-time-text {
    color: #FFFFFF;
}
</style>
