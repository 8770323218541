<template>
    <div>
        <v-row class="align-left mx-2">
            <h1>Receiving Progress</h1>
            <p>You can review the expected volumes for today and compare them to what was actually received. If there are any identified shortages, you should report these to OSCC or Bakkavor.</p>
        </v-row>
        <!--Items begin-->
        <!--v-on:click="findSub(item)" (maybe next year)-->
        <v-card v-for="item in discrepancies" v-bind:key="item.lineNumber" class="mb-5 mt-5 py-5 px-2">
            <v-row justify="center" class="d-flex">
                <v-col cols="3" class="pb-0 pt-0 pl-3" align-self="center">
                    <v-img
                        v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + String(item.lineNumber).padStart(6, '0') + '_BP_11.jpg'" />
                </v-col>
                <v-col cols="9" class="pb-0 pr-0 pt-0 align-left" align-self="center">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-2 pr-0 pl-0">
                            <p class="mb-title item-title text-h6">{{ getItemTitle(item.lineNumber) }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                            <strong class="text-h5">{{ item.lineNumber }}</strong>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0 pb-1 mt-2 pr-0 pl-0">
                            <small class="mb-desc text-h6">{{ item.expected }} expected, <span :class="getReceivedHighlight(item.expected, item.received)">{{item.received}} received</span></small>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

    </div>
</template>
    
<script>
//import moment from 'moment'

import axios from 'axios'
import vars from "@/assets/scripts/vars.js"

export default {
    name: 'ReviewDiscrepancies',
    data: () => {
        return {
            discrepancies: [],
        }
    },
    components: {
        
    },
    beforeMount() {
        this.$store.commit("setTitle", "Receiving Progress")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "")
    },
    mounted() {
        this.$store.commit("setLoading", true)
        this.getDiscrepancies()
    },
    methods: {
        getReceivedHighlight: function(expected, received){
            if (received > expected) {
                return "blue"
            } else if(expected > received){
                return "red"
            }
        },
        getItemTitle: function(lineNumber){
            return this.$store.state.stock[lineNumber].title
        },
        getDiscrepancies() {
            axios({
                "method": "GET",
                "url": vars.apiURL + "stock/receivingProgressToday/" + this.$store.state.user.branch,
                "headers": {
                    "Authorization": "Bearer " + this.$store.state.user.access_token
                }
            }).then((data) => {
                this.discrepancies = data.data
                this.$store.commit("setLoading", false)

            }).catch((error) => {
                console.log(error)
            })
        }
    }
}
</script>
    