var utils = {}

import store from "@/store/index.js"

utils.getBarcodesForLineNumber = function(lineNumber){
    return store.state.stock[lineNumber].barcodes
}

utils.barcodeLookupService = function(barcode){
    console.log(barcode + "( " + barcode.length + " )")

    //If barcode cannot be found, launch distribution/case check
    //Is barcode a distribution barcode?
    if(barcode.startsWith("019") && barcode.length == 34){
        console.log("MEETS CRITERIA")
        //This is a case!
        //Resolve to regular barcode
        let regularBarcode = barcode.substr(0, 16)
        for(let lineNumber of Object.keys(store.state.stock)){
            let item = store.state.stock[lineNumber]
            for(let itemBarcode of item.barcodes){
                console.log("CHECK ITEM BARCODE")
                if (itemBarcode.barcode == regularBarcode || itemBarcode.barcode.substr(0, 16) == regularBarcode) {
                    itemBarcode["item"] = item
                    return itemBarcode
                }
            }
        }
        /*
        for(let lineNumber of store.state.stock){
            let item = store.state.stock[lineNumber]
            for(let itemBarcode of item.barcodes){
                if(itemBarcode.barcode.substr(0, 16) == barcode.substr(0, 16)){
                    //Item found
                    itemBarcode["item"] = item
                    return itemBarcode
                }
            }
        }*/
    }

    // GS1-128 check
    if(barcode.length > 20 && barcode.startsWith("01")){
        barcode = barcode.substr(2, 14)
    }

    for(let lineNumber of Object.keys(store.state.stock)){
        let item = store.state.stock[lineNumber]
        for(let itemBarcode of item.barcodes){
            if (itemBarcode.barcode == barcode) {
                itemBarcode["item"] = item
                return itemBarcode
            }
        }
    }

    // If 12 digits, the zero is missing
    if(barcode.length == 12){
        barcode = "0" + barcode
    }

    //If barcode cannot be found, launch weighted item check
    //Is barcode a PEB?
    if(barcode.length == 13 && barcode.startsWith("02")){
        for(let lineNumber of Object.keys(store.state.stock)){
            let item = store.state.stock[lineNumber]
            for(let itemBarcode of item.barcodes){
                if(itemBarcode.barcode.substr(0, 6) == barcode.substr(0, 6)){
                    itemBarcode["item"] = item
                    return itemBarcode
                }
            }
        }
    }

    return null
}

utils.itemLookupService = function(lineNumber){
    return store.state.stock[lineNumber]
}

export default utils
