<template>
  <div id="bar">
      <v-row justify="center">
          <v-col cols="12" class="ml-1 pt-0">
            <span class="action-text">{{text}}</span>
          </v-col>
      </v-row>
  </div>
</template>

<script>
export default {
  name: 'ActionBar',
  props: {
      text: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#bar{
    background-color: #FF6767;
    color: #FFFFFF;
    font-family: 'Gill Sans', sans-serif;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin-top: 0px;
}
.action-text {
    font-size: 3vh;
}
</style>
