<template>
    <div>
        <div id="newLocationScreen" v-if="newLocationScreen">
            <img src="@/assets/img/ent-loc-scan.png" width="100%">
            <h1>Scan or key a location barcode.</h1>
        </div>

        <div v-if="!newLocationScreen">
                <v-row class="align-left mx-2" v-if="!this.activeItem">
                <h1>Move (Stock Adjustment)</h1>
                <p>This shows you all items required for today's orders which are not in stock. You should not complete this
                    until you have received your expected delivery for the day, or you are sure the items are not coming in.
                </p>
                <ol>
                    <li>Scan the barcode of the item you want to move</li>
                    <li>Next to each location, enter the adjustment amount. To REMOVE 2 units from a location, enter '-2'. To ADD, enter '2'.</li>
                </ol>
            </v-row>

            <div v-if="activeItem === null" style="margin-top: 2rem;">
                <!-- Line number entry mode -->
                <div>
                    <v-text-field hide-details outlined v-model="lineNumberEntry" type="number" class="mb-5" id="lineNumberEntry" />
                </div>

            </div>

            <!-- Item -->
            <div>
                <Item v-bind:lineNumber="activeItem.lineNumber" v-if="this.activeItem" />
            </div>

            <!-- Locations -->
            <v-row justify="center" class="mt-3" v-if="this.activeItem">
                <v-col cols="10">
                    <v-row class="barc-row">
                        <v-col cols="6" style="padding-bottom: 0">
                            <p class="barc-header">Location</p>
                        </v-col>
                        <v-col cols="3" style="padding-bottom: 0">
                            <p class="barc-header">Qty Now</p>
                        </v-col>
                        <v-col cols="3" style="padding-bottom: 0">
                            <p class="barc-header">Chg</p>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="10" style="padding-top: 0;">
                    <v-row v-for="location in stock.stockRecords" :key="location" class="barc-row">
                        
                        <v-col cols="6">
                            <p style="font-size: 200%;">{{location.locationValue}}</p>
                        </v-col>
                        <v-col cols="3">
                            <p style="font-size: 200%;">{{location.quantity}}</p>
                        </v-col>
                        <v-col cols="3">
                            <v-text-field outlined hide-details class="barc-field" type="number" v-model="adjustment[location.locationValue]" />
                        </v-col>
                    </v-row>
                    <v-row class="barc-row">
                        <v-col cols="12">
                            <BSMButton text="+ Add New Location" color="#FEEE25" textColor="#000000" v-on:click="showNewLocationScreen()" key="fuckoff" />
                        </v-col>
                    </v-row>
                    <v-row style="margin-top:2rem;">
                        <BSMButton v-if="this.activeItem" text="Save Changes" color="#4873D1" textColor="#000000" v-on:click="makeAdjustments()" />
                    </v-row>
                </v-col>
            </v-row>
        </div>

        <WarningDialog v-bind:dialog="showWarningDialog" v-on:closeDialog="showWarningDialog=false" title="Alert" v-bind:text="warningText"/>

    </div>
</template>
    
<script>
//import moment from 'moment'
import utils from "@/assets/scripts/utils.js"

import WarningDialog from "@/components/WarningDialog.vue"
import Item from "@/components/Item.vue"
import BSMButton from "@/components/BSMButton.vue"

export default {
    name: 'Move',
    data: () => {
        return {
            showWarningDialog: false,
            warningText: "",
            activeItem: null,
            stock: [],
            adjustment: {},
            changeQuantity: 0,
            lineNumberEntry: "",
            newLocationScreen: false
        }
    },
    components: {
        WarningDialog,
        Item,
        BSMButton
    },
    beforeMount() {
        this.$store.commit("setTitle", "Move")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Scan an item")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    mounted() {

    },
    watch: {
        lineNumberEntry: function () {
            var item = utils.itemLookupService(this.lineNumberEntry);
            if (item != null) {
                this.activeItem = item;
                this.stock = this.$store.state.stock[item.lineNumber]
                        //Create adjustments
                        this.stock.stockRecords.forEach((stockRecord) => {
                            this.adjustment[stockRecord.locationValue] = 0
                        })
            }
        }
    },
    methods: {
        showNewLocationScreen(){
            this.newLocationScreen = true
        },
        makeAdjustments(){
            //Make adjustments
            Object.keys(this.adjustment).forEach((location) => {
                if(this.adjustment[location] != 0){
                    //Adjustment is not 0
                    this.$store.dispatch("makeStockAdjustment", {lineNumber: this.activeItem.lineNumber, location: location, quantity: parseInt(this.adjustment[location])})
                } 
            })
            //Save changes
            this.$store.commit("setActionText", "Scan an item")
            this.activeItem = null
            this.stock = []
            this.adjustment = {}
        },
        scanned: function(e) {
            if(this.$route.name == "Move"){
                if(this.newLocationScreen){
                    //Add new location
                    if(e.detail.value.length == 5){
                        e.detail.value = e.detail.value.toUpperCase()
                        //Location, location!
                        //Does the location already exist?
                        if(Object.keys(this.stock.stockRecords).find((location) => location.locationValue == e.detail.value)){
                            //Already exists
                            this.warningText = "This location already has stock assigned to it."
                            this.showWarningDialog = true
                        }else{
                            this.stock.stockRecords.push({locationValue: e.detail.value, quantity: 0})
                            this.adjustment[e.detail.value] = 0
                            this.newLocationScreen = false
                        }
                    }
                }else{
                    var item = utils.barcodeLookupService(e.detail.value)
                    if(item.lineNumber == null){
                        //Barcode doesn't exist
                        this.warningText = "Barcode not found"
                        this.showWarningDialog = true
                    }else{
                        //Item was found
                        this.$store.commit("setActionText", "")
                        this.activeItem = item
                        this.lineNumberEntry = item.lineNumber
                        this.stock = this.$store.state.stock[item.lineNumber]
                        //Create adjustments
                        this.stock.stockRecords.forEach((stockRecord) => {
                            this.adjustment[stockRecord.locationValue] = 0
                        })
                    }
                }
            }
        }
    }
}
</script>
    