<template>
    <div>
        <GillTitle title="Review order contents" />

        <div>
            <OrderItem v-bind:orderedItem="item" v-for="item in this.$store.state.activeOrder.items" :key="item.lineNumber" />
        </div>

        <router-link style="text-decoration: none; color: inherit;" to="/customer-handover/dispatch" v-if="this.$store.state.activeOrder.type == 'ENTERTAINING COLLECTION'"><WrButton class="mt-6" text="Next >" /></router-link>
        <div style="margin-top: 2rem;" v-if="this.$store.state.activeOrder.type != 'ENTERTAINING COLLECTION'">
            <h3>You cannot dispatch a PickList for this order because it is not an Entertaining Collection.</h3>
        </div>
    </div>
</template>
    
<style>
#recommended-location {
    font-size: 4vh;
}
</style>
    
<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import GillTitle from "@/components/GillTitle.vue"
import WrButton from "@/components/WrButton.vue"

import OrderItem from "@/components/Order/OrderItem.vue"

export default {
    name: 'OrderReview',
    data: () => {
        return {
            
        }
    },
    components: {
        GillTitle,
        OrderItem,
        WrButton
    },
    beforeMount() {
        this.$store.commit("setTitle", "Customer Collection")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Review order contents")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    watch: {

    },
    methods: {

        scanned: function (e) {
            console.log(e.detail)
        }
    },
    computed: {

    }
}
</script>
    