<template>
    <v-card class="mt-5 px-2 rounded text-left">
        <v-row class="orange lighten-4">
            <v-col cols="3" class="d-flex">
                <v-img v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/3/LN_' + item.lineNumber.toString().padStart(6, '000000') + '_BP_3.jpg'" contain />
            </v-col>
            <v-col cols="7">
                <h2>{{item.title}}</h2>
                <h3><span v-if="item.mass">({{ getMass }}) - </span>{{item.lineNumber}}</h3>
                <h4>{{availableQuantity}}/{{orderedItem.quantity}} available ({{itemStockEstimate}} in stock)</h4>
            </v-col>
            <v-col cols="2" class="d-flex justify-center align-center text-h3" v-bind:style="{'background-color':statusMarkerColor}">
                <h1>{{statusMarkerContent}}</h1>
            </v-col>
        </v-row>
        <!-- If substitution needed-->
        <!--<v-row v-if="availableQuantity < orderedItem.quantity">
            <v-col cols="3" class="d-flex">
                <v-img v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/3/LN_376437_BP_3.jpg'" contain />
            </v-col>
            <v-col cols="9">
                <b class="orange white--text rounded px-5">SUBSTITUTION</b>
                <h2>25cm Football Cake</h2>
                <h3><span v-if="item.mass">({{item.mass}}) - </span>376437</h3>
                <h4>2/2 needed available (3 in stock)</h4>
            </v-col>
        </v-row>-->
    </v-card>
</template>

<script>
export default {
    name: 'OrderItem',
    props: ['orderedItem'],
    computed: {
        getMass: function(){
            if(this.item.mass && !isNaN(this.item.mass)){
                return parseFloat(this.item.mass) + " kg"
            } else {
                return "Unit"
            }
        },
        item: function(){
            return this.$store.state.stock[this.orderedItem.lineNumber]
        },
        itemStockEstimate: function(){
            let total = 0
            this.item.stockRecords.forEach((record) => {
                total += record.quantity
            })
            return total
        },
        availableQuantity: function (){
            if(this.itemStockEstimate >= this.orderedItem.quantity){
                return this.orderedItem.quantity
            } else {
                return this.itemStockEstimate
            }
        },
        statusMarkerContent: function(){
            if(this.availableQuantity <= 0){
                return "X"
            } else if(this.availableQuantity < this.orderedItem.quantity){
                return "!"
            }else{
                return "✓"
            }
        },
        statusMarkerColor: function(){
            if(this.availableQuantity <= 0){
                return "red"
            } else if(this.availableQuantity < this.orderedItem.quantity){
                return "orange"
            }else{
                return "green"
            }
        }
    },
    methods: {
       
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.item-container {
    border: 1px solid black;
    padding: 10px;
    margin: 10px;
    text-align: left;
}
</style>
