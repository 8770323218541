const debug = true

import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import Welcome from '../views/Welcome.vue'
import Receive from '../views/Receive.vue'
import Stock from '../views/Stock.vue'
import Status from '../views/Status.vue'
import Assortment from '../views/Assortment.vue'

//Customer handover
import CustomerHandover from '../views/CustomerHandover/CustomerHandover.vue'
import OrderReview from '../views/CustomerHandover/OrderReview.vue'
import Dispatch from '../views/CustomerHandover/Dispatch.vue'

//Remote pick
import RemotePick from '../views/RemotePick/RemotePick.vue'
import PickPickList from '../views/RemotePick/PickPickList.vue'

//Prepare Substitutions
import PrepareSubstitutions from '../views/PrepareSubstitutions/PrepareSubstitutions.vue'
import FindSubstitution from '../views/PrepareSubstitutions/FindSubstitution.vue'

//Return Overages
import ReturnOverages from '../views/ReturnOverages/ReturnOverages.vue'
import ReturnLine from '../views/ReturnOverages/ReturnLine.vue'

//Review Discrepancies
import ReviewDiscrepancies from '../views/ReviewDiscrepancies/ReviewDiscrepancies.vue'

//Uncollected Orders
import UncollectedOrders from '../views/UncollectedOrders/UncollectedOrders.vue'

//Pending Orders
import PendingOrders from '../views/PendingOrders/PendingOrders.vue'

//Recent PickLists
import RecentPickLists from '../views/RecentPickLists/RecentPickLists.vue'

// Quick Pick
import QuickPick from '../views/QuickPick/QuickPick.vue'

// Move
import Move from '../views/Move/Move.vue'

Vue.use(VueRouter)

import store from '../store/index.js'

const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/welcome',
        name: 'Welcome',
        component: Welcome
    },
    //RemotePick
    {
        path: '/remote-pick',
        name: 'RemotePick',
        component: RemotePick
    },
    {
        path: '/remote-pick/pick-picklist',
        name: 'PickPickList',
        component: PickPickList
    },


    {
        path: '/rec',
        name: 'Receive',
        component: Receive
    },
    {
        path: '/stock',
        name: 'Stock',
        component: Stock
    },
    {
        path: '/status',
        name: 'Status',
        component: Status
    },
    {
        path: '/assortment',
        name: 'Assortment',
        component: Assortment
    },

    //Customer handover
    {
        path: '/customer-handover',
        name: 'CustomerHandover',
        component: CustomerHandover
    },
    {
        path: '/customer-handover/order-review',
        name: 'OrderReview',
        component: OrderReview
    }, {
        path: '/customer-handover/dispatch',
        name: 'Dispatch',
        component: Dispatch
    },

    //Prepare substitutions
    {
        path: '/prepare-substitutions',
        name: 'PrepareSubstitutions',
        component: PrepareSubstitutions
    },
    {
        path: '/prepare-substitutions/find-substitution',
        name: 'FindSubstitution',
        component: FindSubstitution
    },

    //Return Overages
    {
        path: '/return-overages',
        name: 'ReturnOverages',
        component: ReturnOverages
    },
    {
        path: '/return-overages/return-line',
        name: 'ReturnLine',
        component: ReturnLine
    },

    //Review discrepancies
    {
        path: '/review-discrepancies',
        name: 'ReviewDiscrepancies',
        component: ReviewDiscrepancies
    },

    //Uncollected orders
    {
        path: '/uncollected-orders',
        name: 'UncollectedOrders',
        component: UncollectedOrders
    },

    //PendingOrders orders
    {
        path: '/pending-orders',
        name: 'PendingOrders',
        component: PendingOrders
    },

    {
        path: '/recent-picklists',
        name: 'RecentPickLists',
        component: RecentPickLists
    },

    // Quick Pick
    {
        path: '/quick-pick',
        name: 'QuickPick',
        component: QuickPick,
    },

    // Move
    {
        path: '/move',
        name: 'Move',
        component: Move,
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    if (to.name != "Login" && !debug) {
        if (store.state.partnerNumber == "") {
            next("/")
        }
    }
    next()
})

export default router
