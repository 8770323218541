<template>
    <div>
        <!--PickList Header-->
        <v-card class="align-left px-2 py-2">
            <v-row class="d-flex">
                <v-col cols="9">
                    <h1>{{ pickList.orderNumber }}</h1>
                    <h2>for {{ getCustomerName }}</h2>
                    <h3>PickList {{ pickList.pickListID }}</h3>
                </v-col>
                <v-col cols="3" class="red d-flex justify-center align-center white--text flex-column">
                    <strong class="text-h3">{{ elapsed }}</strong>
                    <strong>{{ mins }}</strong>
                </v-col>
            </v-row>
        </v-card>

        <!--Picker Note-->
        <v-card class="align-left px-2 py-2 mt-5" v-if="pickList.pickerNote != ''">
            <v-row class="d-flex">
                <v-col cols="12">
                    <strong>{{pickList.pickerNote}}</strong>
                </v-col>
            </v-row>
        </v-card>
        
        <!--Items begin-->
        <PickItem v-for="item in pickList.pickListItems" :key="item.lineNumber" :pickListItem="item" :pickList="pickList"
            v-on:click="setActivePickListItem(item)"></PickItem>

        <!--Complete button-->
        <v-btn v-if="(pickedItemsCount == totalItemsQuantity || allowOverride)" color="green" class="white--text py-12 text-h4" style="width: 100%;" v-on:click="completeOrder">Complete order</v-btn>

    </div>
</template>
    
<script>
import PickItem from "@/components/PickItem.vue"

export default {
    name: 'PickPickList',
    data: () => {
        return {

        }
    },
    components: {
        PickItem
    },
    beforeMount() {
        this.$store.commit("setTitle", "Live Picks")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "")
    },
    methods: {
        completeOrder: function () {
            this.$store.dispatch("completePickList", this.pickList)
            this.$router.push("/remote-pick")
        }
    },
    computed: {
        pickList: function () {
            return this.$store.state.activePicks.find((activePick) => {
                return activePick.pickListID == this.$store.state.activePickList
            })
        },
        getCustomerName: function () {
            return this.$store.state.orders[this.pickList.orderNumber].customer.name
        },
        pickedItemsCount: function () {
            let count = 0
            this.pickList.pickListItems.forEach((item) => {
                count += item.pickedQty
            })
            return count
        },
        allowOverride: function(){            
            var res = false
            this.pickList.pickListItems.forEach((item) => {
                let stockRecords = this.$store.state.stock[item.lineNumber]
                console.log(stockRecords)
                let stockQty = 0
                stockRecords.stockRecords.forEach((record) => {
                    stockQty += record.quantity
                })
                if(stockQty < item.quantity){
                    res = true
                }
            })
            return res
        },
        totalItemsQuantity: function () {
            let count = 0
            this.pickList.pickListItems.forEach((item) => {
                count += item.quantity
            })
            return count
        },
        elapsed: function () {
            const current = Date.now()
            const dispatched = new Date(this.pickList.dispatchDateTime)
            return Math.round((current - dispatched.valueOf()) / 1000 / 60)
        },
        mins: function () {
            if (this.elapsed == 1) {
                return "min"
            } else {
                return "mins"
            }
        }
    }
}
</script>
    