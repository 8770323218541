<template>
    <div>
        <v-row justify="center">
            <v-col cols="11">
                <GillTitle v-bind:title="welcomeTitle" />
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="12" class="pl-2 pr-2" justify="center">
                <ModeButton title="Customer Collection" icon="customer-collection"
                    v-on:click="navigate('customer-handover')" hero />
                <ModeButton title="Live Picks" description="Pick requested items from storage" icon="live-picks"
                    v-on:click="navigate('remote-pick')" hero />

                <ModeButton title="Receive & Put-Away" description="Receive and put-away simultaneously" icon="receive"
                    v-on:click="navigate('rec')" />
                <ModeButton title="Move" description="Move items between put-away locations" icon="move"
                    v-on:click="navigate('move')" />
                <ModeButton title="Prepare Substitutions" description="Put aside substitutions for unfulfillable orders"
                    icon="prepare-subs" v-on:click="navigate('prepare-substitutions')" />
                <ModeButton title="Excess Stock" description="Identify overages and return to the shopfloor"
                    icon="excess-stock" v-on:click="navigate('return-overages')" />
                <ModeButton title="Receiving Progress" description="Compare expected volumes to actual"
                    icon="receive-progress" v-on:click="navigate('review-discrepancies')" />
                <ModeButton title="Pending Orders" description="Review orders due to be collected today"
                    icon="pending-orders" v-on:click="navigate('pending-orders')" />
                <ModeButton title="Overdue Orders" description="Review orders past their collection slot"
                    icon="overdue-orders" v-on:click="navigate('uncollected-orders')" />
                <ModeButton title="Picklist History" description="Review recent PickLists" icon="picklist-history"
                    v-on:click="navigate('recent-picklists')" />
                <ModeButton title="Ecom Pick" description="Inventory manage with BPS picking" icon="ecom-pick"
                    v-on:click="navigate('quick-pick')" />
                <!-- <ModeButton title="Stock Count" description="Perform a stock count for putaway locations" icon="stock-count"
                    v-on:click="navigate('stock-count')" /> -->
                <ModeButton v-if="$store.state.user.admin" title="Stock Level Set"
                    description="Adjust stock levels of items" icon="stock-levels" v-on:click="navigate('stock')" />
                <ModeButton v-if="$store.state.user.admin" title="Assortment barcodes"
                    description="Manage barcodes for lines in assortment" icon="assortment-barcodes"
                    v-on:click="navigate('assortment')" />
                <ModeButton title="Logout" v-bind:description=logoffText icon="logout" v-on:click="logOff()" />
            </v-col>
        </v-row>

    </div>
</template>

<script>
// @ is an alias to /src
import GillTitle from "@/components/GillTitle.vue"
import ModeButton from "@/components/ModeButton.vue"

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

export default {
    name: 'Welcome',
    data: () => {
        return {
            partnerNumber: null,
            loadLoginButton: false,
            showErrorDialog: false,
            errorDialogText: null
        }
    },
    components: {
        GillTitle,
        ModeButton
    },
    mounted() {
        this.$store.commit("setTitle", "Main Menu")
        this.$store.commit("enableNavbar", false)
        this.$store.commit("setActionText", "")
    },
    methods: {
        logOff: function () {
            this.$store.dispatch("logOff")
            this.$router.push("/")
        },
        navigate: function (route) {
            this.$router.push(route)
        }
    },
    computed: {
        disableLoginButton: function () {
            return this.partnerNumber == null || this.loadLoginButton || this.partnerNumber.length != 8
        },
        welcomeTitle: function () {
            if (this.$store.state.user != null) {
                return "Welcome, " + this.$store.state.user.fullName.split(" ")[0] + "!"
            }
            else {
                return "Welcome, Partner!"
            }
        },
        logoffText: function () {
            if (this.$store.state.user != null) {
                return "Log-off " + this.$store.state.user.fullName.split(" ")[0]
            }
            else {
                return "Log-off"
            }
        }
    }
}
</script>
