<template>
    <div>
        <v-row class="align-left mx-2">
            <h1>Return line now</h1>
            
            <v-card class="mb-5 mt-5 py-5 px-2">
            <v-row justify="center" class="d-flex">
                <v-col cols="3" class="pb-0 pt-0 pl-3" align-self="center">
                    <v-img
                        v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + item.lineNumber.toString().padStart(6, '000000') + '_BP_11.jpg'" />
                </v-col>
                <v-col cols="9" class="pb-0 pr-0 pt-0 align-left" align-self="center">
                    <v-row>
                        <v-col cols="12" class="pb-0 pt-2 pr-0 pl-0">
                            <p class="mb-title item-title text-h4">{{ getItemTitle(item.lineNumber) }}</p>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                            <strong class="text-h5">{{ item.lineNumber }}</strong>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0 pb-1 mt-2 pr-0 pl-0">
                            <small class="mb-desc text-h6">{{item.entCollection}} needed for Ent. collections</small><br/>
                            <!--<small class="mb-desc text-h6">{{item.grocery}} needed for grocery orders</small><br/>-->
                            <small class="mb-desc text-h6">{{item.quantityInStock}} currently in stock</small><br/>
                            <small class="mb-desc text-h6 yellow">Over by {{item.quantityOver}}</small>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card>

        <!-- Pick locations -->
      <div v-if="stockRecords.length !== 0">
        <!-- Most populated pick location  -->
        <v-row justify="center" align="center" class="mt-12" style="background-color: #ffff00">
          <h1 id="recommended-location">
            Pick location: <b>{{ stockRecords[0].locationValue }}</b> ({{ stockRecords[0].quantity }} available)
          </h1>
        </v-row>

        <!-- If more than one location, display the rest here -->
        <div v-if="stockRecords.length > 1">
          <v-row justify="center" align="center" class="mt-4">
            <h2>
              Other locations: <b>{{ stockRecords.slice(1).map(sr => `${sr.locationValue} (${sr.quantity})`).join(", ") }}</b>
            </h2>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row justify="center" align="center" class="mt-12 red rounded white--text">
          <h1 id="recommended-location">
            No pick locations found for this item.
          </h1>
        </v-row>
      </div>

        <v-row justify="center" class="mt-3">
            <v-col cols="10">
                <BSMQuantity v-bind:quantity="quantity" v-on:setQty="setQty($event)" />
            </v-col>
        </v-row>

        <v-row v-if="locationValue != null">
                <v-col cols="12">
                    <BSMButton v-on:click="returnIt()" text="Return to shopfloor" color="#FF0000" textColor="#FFFFFF" />
                </v-col>
            </v-row>
        </v-row>

    </div>
</template>
    
<script>
/*import moment from 'moment'
import axios from 'axios'
import vars from "@/assets/scripts/vars.js"*/
import BSMQuantity from "@/components/BSMQuantity.vue"
import BSMButton from "@/components/BSMButton.vue"

export default {
    name: 'ReturnLine',
    data: () => {
        return {
            quantity: 1,
            locationValue: null
        }
    },
    components: {
        BSMQuantity,
        BSMButton
    },
    beforeMount() {
        this.$store.commit("setTitle", "Excess Stock")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Scan pick location")
    },
    mounted() {
    },
    created(){
        this.quantity = this.$store.state.overageReturn.quantityOver
        document.addEventListener('keyInputXd', this.scanned)
    },
    computed: {
        item() {
            return this.$store.state.overageReturn
        },
        stockRecords: function() {
            return this.$store.state.stock[this.item.lineNumber].stockRecords
        }
    },
    methods: {
        returnIt: function(){
            this.$store.commit("setLoading", true)
                this.$store.dispatch("returnStock", {
                lineNumber: this.item.lineNumber,
                location: this.locationValue,
                quantityToDecrease: this.quantity,
                });

                this.$store.commit("setOverageReturn", null)
                this.$router.push("/return-overages")
            this.$store.commit("setLoading", true)
        },
        setQty: function(q){
            this.quantity = q
        },
        getItemTitle: function(lineNumber) {
            return this.$store.state.stock[lineNumber].title
        },

        scanned: function(e) {
            if(this.$route.name == "ReturnLine"){
                    if(e.detail.value.length == 5){
                        this.locationValue = e.detail.value.toUpperCase()
                        this.$store.commit("setActionText", "")
                    }
            }
        }
    }
}
</script>
    