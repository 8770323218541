<template>
    <div class="mb-6">
        <v-card class="mb-0" v-on:click="$emit('click')">
            <v-row justify="center" className="d-flex">
                <v-col cols="9" class="pb-6 pl-6">
                    <v-row>
                        <v-col cols="12" class="text-left pb-0">
                            <h1 class="">{{ pick.orderNumber }}</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" class="text-left pt-0">
                            <strong class="">{{ getCustomerName }}</strong>
                        </v-col>
                    </v-row>
                    <v-row class="mt-0">
                        <v-col cols="12" class="pt-0 text-left">
                            <strong class="mb-desc">{{ pickedItemsCount }}/{{ totalItemsQuantity }} picked</strong>
                        </v-col>
                    </v-row>
                    <v-row class="mt-3">
                        <v-col cols="12" class="pt-0 text-left">
                            <strong v-for="picker in pick.pickers" v-bind:key="picker.partnerNumber" class="mb-desc orange rounded px-3 py-1 mr-1"><v-icon color="black" class="mr-2">mdi-account</v-icon>{{picker.picker.fullName.split(" ")[0]}}</strong>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="3" v-bind:class="remainingTimeColour + ' d-flex justify-center align-center white--text flex-column'">
                    <strong class="text-h3">{{elapsed}}</strong>
                    <strong>{{mins}}</strong>
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
/*import PickHeader from "@/components/PickHeader.vue"
import PickItem from "@/components/PickItem.vue"
*/
export default {
    name: 'Pick',
    props: {
        pick: Object
    },
    components: {
        /*PickHeader,
        PickItem*/
    },
    computed: {
        pickedItemsCount: function () {
            let count = 0
            this.pick.pickListItems.forEach((item) => {
                count += item.pickedQty
            })
            return count
        },
        totalItemsQuantity: function () {
            let count = 0
            this.pick.pickListItems.forEach((item) => {
                count += item.quantity
            })
            return count
        },
        elapsed: function () {
            const current = Date.now()
            const dispatched = new Date(this.pick.dispatchDateTime)
            return Math.round((current - dispatched.valueOf())/1000/60)
        },
        remainingTimeColour: function(){
            var val = "shortTimeElapsed"
            switch(true){
                case this.elapsed < 3:
                    val = "shortTimeElapsed"
                    break
                case this.elapsed < 5:
                    val = "mediumTimeElapsed"
                    break
                default:
                    val = "longTimeElapsed"
            }
            return val
        },
        getCustomerName: function(){
            return this.$store.state.orders[this.pick.orderNumber].customer.name
        },
        mins: function(){
            if(this.elapsed == 1){
                return "min"
            }else{
                return "mins"
            }
        }
    },
    data: function () {
        return {
            showItems: false
        }
    },
    destroyed() {
        this.$store.commit("setActionText", "Select an order")
    },
    created() {
    },
    methods: {

    }
}
</script>

<style scoped>
#pick-detail {
    background-color: #C4C4C4;
}
</style>
