<template>
    <div>
        <slot> </slot>
    </div>
</template>

<script>
export default {
    name: 'OrderList',
    props: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
