<template>
    <div class="mb-3">
        <div v-if="hero" class="hero-container pa-10" v-on:click="$emit('click')">
            <v-row justify="center pb-3" >
                    <v-img v-bind:src="btnIcon" max-width="50" />
            </v-row>
            <v-row justify="center">
                <v-col cols="12" class="pb-0">
                    <div class="hero-name">{{title}}</div>
                </v-col>
            </v-row>
        </div>

        <div v-else class="container pa-6" v-on:click="$emit('click')">
            <v-row align="center">
                <v-col cols="2">
                    <v-img v-bind:src="btnIcon" max-width="50" />
                </v-col>
                <v-col cols="10" class="pl-3">
                    <div class="name">{{title}}</div>
                    <!-- It looks cluttered with the description lol -->
                    <!-- <div class="description">{{description}}</div> -->
                </v-col>
            </v-row>
        </div>
    </div>

    


</template>

<script>
export default {
    name: 'ModeButton',
    props: {
        title: String,
        description: String,
        icon: String,
        hero: Boolean
    },
    computed: {
        btnIcon: function(){
            return `/assets/img/mode-icons/new/${this.icon}.png`
        }
    }
}
</script>

<style scoped>
    .hero-container {
        background-color: #5B8226;
        color: #FFFFFF;
    }

    .hero-name {
        letter-spacing: 4px;
        font-size: 1.4rem;
        text-transform: uppercase;
    }

    .container {
        background-color: #F1F8E8;
        color: #5B8226;
        text-align: left;
    }

    .name {
        letter-spacing: 4px;
        font-size: 1.4rem;
        text-transform: uppercase;
    }
</style>
