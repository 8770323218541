<template>
<div>
    <v-text-field v-if="showUnknownLine" hide-details outlined v-model="lineNumberEntry" type="number" class="mb-5" id="lineNumberEntry"/>
    <GillTitle v-if="showUnknownLine" title="The barcode you scanned is unknown. Please enter the associated line number in the box above." />
    <GillTitle v-if="activeItem==null && !showUnknownLine" title="Scan/key an item to begin receiving or stock adjusting." />
    <img v-if="activeItem==null && !showUnknownLine" src="@/assets/img/ent-scan.png" width="100%" style="margin-top: 3rem;">

    <div v-if="activeItem!=null">
        <Item v-bind:lineNumber="activeItem.lineNumber" />
        <v-row justify="center" class="mt-3">
            <v-col cols="10">
                <BSMQuantity v-bind:quantity="quantity" v-on:setQty="setQty($event)" />
            </v-col>
        </v-row>

        <v-row justify="center" align="center" class="mt-12" style="background-color: #FFFF00;">
            <h1 id="recommended-location" v-if="bestLocation != null">Recommended location: <b>{{bestLocation}}</b></h1>
        </v-row>
    </div>
    <WarningDialog v-bind:dialog="showLocationDialog" v-on:closeDialog="showLocationDialog=false" title="Scan a location" text="Not a valid putaway location. You must scan a location for this item or press 'Back'."/>
</div>
</template>

<style>
    #recommended-location {
        font-size: 4vh;
    }
</style>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import BSMQuantity from "@/components/BSMQuantity.vue"
import Item from "@/components/Item.vue"
import GillTitle from "@/components/GillTitle.vue"
import WarningDialog from "@/components/WarningDialog.vue"

import utils from "@/assets/scripts/utils.js"

export default {
    name: 'Receive',
    data: () => {
        return {
            activeItem: null,
            quantity: 1,
            showLocationDialog: false,
            bestLocation: null,
            showUnknownLine: false,
            lineNumberEntry: "",
            newBarcode: ""
        }
    },
    components: {
        BSMQuantity,
        Item,
        GillTitle,
        WarningDialog
    },
    beforeMount() {
        this.$store.commit("setTitle", "Receive & Put-Away")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "Scan/key an item")
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    watch: {
        lineNumberEntry: function(){
            var item = utils.itemLookupService(this.lineNumberEntry)
            if(item != null){
                this.activeItem = item
                this.quantity = 1
                this.showUnknownLine = false
                this.$store.commit("setActionText", "Now scan put-away location")
                this.findBestLocation()
            }
        }
    },
    methods: {
        findBestLocation: function(){
            const stockEstimates = this.$store.state.stock[this.activeItem.lineNumber]
            if (stockEstimates) {
                // Find most populated location
                let mostPopulatedStockRecord = null
                stockEstimates.stockRecords.forEach((stockRecord) => {
                    if (mostPopulatedStockRecord == null || stockRecord.quantity > mostPopulatedStockRecord.quantity) {
                        mostPopulatedStockRecord = stockRecord
                    }
                })

                this.bestLocation = mostPopulatedStockRecord.locationValue
            } else {
                this.bestLocation = null
            }
        },
        setQty: function(newQty){
            this.quantity = newQty
        },
        logOff: function() {
            this.$store.dispatch("logOff")
            this.$router.push("/")
        },
        scanned: function(e) {
            if(this.$route.name == "Receive"){
                let evnt = null
                if(this.activeItem == null){
                    console.log("BLS")
                    var item = utils.barcodeLookupService(e.detail.value)
                    console.log("PERFORMED BLS")
                    if(item == null){
                        this.newBarcode = e.detail.value
                        this.showUnknownLine = true
                        evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "warning"
                            }
                        })
                        document.dispatchEvent(evnt)
                    }else{
                        evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "good"
                            }
                        })
                        document.dispatchEvent(evnt)

                        this.showUnknownLine = false
                        this.activeItem = item
                        this.quantity = item.quantity
                        this.$store.commit("setActionText", "Now scan put-away location")
                        this.findBestLocation()
                    }
                }else{
                    //Should be a location
                    if(e.detail.value.length == 5){
                        evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "good"
                            }
                        })
                        document.dispatchEvent(evnt)
                        
                        //Location
                        this.$store.dispatch("adjustStock", {
                            "lineNumber": this.activeItem.lineNumber,
                            "location": e.detail.value,
                            "quantity": this.quantity
                        })

                        //If this is a new barcode, we need to register it.
                        if(this.newBarcode != ""){
                            //Get list of barcodes
                            let type = "EAN-13"
                            if(this.newBarcode.length != 13){
                                type = "CODE-128"
                            }
                            this.$store.dispatch("registerBarcode", {
                                "item": this.activeItem,
                                "barcode": {
                                    "barcode": this.newBarcode,
                                    "quantity": this.quantity,
                                    "type": type
                                }
                            })
                        }

                        this.lineNumberEntry = ""
                        this.newBarcode = ""
                        this.activeItem = null
                        this.quantity = 1
                        this.bestLocation = null
                        this.$store.commit("setActionText", "Scan/key an item")
                    }else{
                        //Not a valid location
                        this.showLocationDialog = true
                        evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "bad"
                            }
                        })
                        document.dispatchEvent(evnt)
                    }
                }
            }
        }
    },
    computed: {
        disableLoginButton: function() {
            return this.partnerNumber == null || this.loadLoginButton || this.partnerNumber.length != 8
        },
        welcomeTitle: function() {
            return 'Hey, ' + this.$store.state.partnerName.split(" ")[0] + '!'
        },
        logoffText: function() {
            return 'Log off ' + this.$store.state.partnerName
        }
    }
}
</script>
