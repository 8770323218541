import axios from 'axios'
import Vue from 'vue'
import Vuex from 'vuex'

import vars from "@/assets/scripts/vars.js"
//const axios = require("axios").default

Vue.use(Vuex)

var store = new Vuex.Store({
    state: {
        pendingChanges: 0,
        activeTitle: "Waitrose & Partners",
        showNavbar: false,
        actionText: "",
        confirmResponse: false,
        bsmQuantity: 1,

        stock: {},

        orders: {},

        activePicks: [],

        activeOrder: null,
        activePickList: null,
        activePickListItem: {"lineNumber": 0},

        loading: false,

        //Prepare subs
        activeSubItem: null,

        user: null,

        overageReturn: null
        //REMOVE AFTER TESTING
    },
    mutations: {
        increasePendingChanges(state) {
            state.pendingChanges++
        },
        decreasePendingChanges(state) {
            state.pendingChanges--
        },
        setTitle(state, title) {
            state.activeTitle = title
        },
        enableNavbar(state, show) {
            state.showNavbar = show
        },
        setActionText(state, text) {
            state.actionText = text
        },
        setConfirmResponse(state, res) {
            state.confirmResponse = res
        },
        setAssortment(state, assortment) {
            state.assortment = assortment
        },
        setStock(state, stock) {
            state.stock = stock
        },
        setOrders(state, orders) {
            state.orders = orders
        },
        setActivePicks(state, picks) {
            state.activePicks = picks

        },
        setActiveOrder(state, order) {
            state.activeOrder = order
        },
        setActivePickList(state, pickList) {
            state.activePickList = pickList
        },
        setActivePickListItem(state, pickListItem) {
            state.activePickListItem = pickListItem
        },
        setUser(state, user) {
            state.user = user
        },
        setLoading(state, loading) {
            state.loading = loading
        },
        pickItemPickList(state, payload) {
            state.activePicks.forEach((activePick) => {
                if(activePick.pickListID == payload.pickList.pickListID){
                    activePick.pickListItems.forEach((pickListItem) => {
                        if(pickListItem.lineNumber == payload.item.lineNumber){
                            pickListItem.pickedQty += payload.quantity
                        }
                    })
                }
            })
        },
        modifyStockLevel(state, payload) {
            state.stock[payload.lineNumber].stockLevel += parseInt(payload.quantity)
        },
        modifyStockFromQuickPick(state, payload) {
            state.stock[payload.lineNumber].stockLevel -= payload.quantityToDecrease
        },
        registerBarcode(state, payload){
            state.stock[payload.item.lineNumber].barcodes.push(payload.barcode)
        },
        setActiveSubItem(state, subItem) {
            state.activeSubItem = subItem
        },
        setOverageReturn(state, item){
            state.overageReturn = item
        }
    },
    actions: {
        registerBarcode(context, payload){
            context.commit('registerBarcode', payload)
            context.commit('increasePendingChanges')
            registerBarcode(payload.item, payload.barcode)
        },
        registerSubstitution(context, payload){
            registerSubstitution(payload)
        },
        pickItemFromPickList(context, payload) {
            //Do not allow pick greater than quantity
            if((payload.item.pickedQty + payload.quantity) > payload.item.quantity){
                payload.quantity = payload.item.quantity - payload.item.pickedQty
            }
            context.commit('pickItemPickList', payload)
            context.commit('increasePendingChanges')
            pickItemFromPickList(payload.item, payload.quantity, payload.pickList, payload.location)
        },
        completePickList(context, pickList) {
            context.commit('setActivePickList', null)
            context.commit('increasePendingChanges')
            completePickList(pickList)
        },
        adjustStock(context, payload) {
            context.commit('modifyStockLevel', payload)
            context.commit('increasePendingChanges')
            receiveStock(payload.lineNumber, payload.location, parseInt(payload.quantity))
        },
        makeStockAdjustment(context, payload) {
            context.commit('modifyStockLevel', payload)
            context.commit('increasePendingChanges')
            makeStockAdjustment(payload.lineNumber, payload.location, payload.quantity)
        },
        quickPick(context, payload) {
            context.commit('modifyStockFromQuickPick', payload)
            context.commit('increasePendingChanges')
            quickPick(payload.lineNumber, payload.location, payload.quantityToDecrease)
        },
        returnStock(context, payload) {
            context.commit('modifyStockFromQuickPick', payload)
            context.commit('increasePendingChanges')
            returnStock(payload.lineNumber, payload.location, payload.quantityToDecrease)
        }
    },
    modules: {}
})

export default store

function pickItemFromPickList(item, quantity, pickList, location){
    axios({
        method: "PATCH",
        url: vars.apiURL + "picklist/" + pickList.pickListID,
        data: {
            "lineNumber": item.lineNumber,
            "operation": "PICK",
            "location": location
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(pickItemFromPickList(item, quantity), 10000)
    })
}

function completePickList(pickList){
    axios({
        method: "PATCH",
        url: vars.apiURL + "picklist/" + pickList.pickListID + "/complete",
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(completePickList(pickList), 10000)
    })
}

function receiveStock(lineNumber, location, quantity){
    axios({
        method: "POST",
        url: vars.apiURL + "stock/receive",
        data: {
            "lineNumber": lineNumber,
            "location": location,
            "quantity": quantity,
            "branch": store.state.user.branch
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {receiveStock(lineNumber, location, quantity)}, 10000)
    })
}

function makeStockAdjustment(lineNumber, location, quantity){
    axios({
        method: "PATCH",
        url: vars.apiURL + "stock/adjust",
        data: {
            "lineNumber": lineNumber,
            "location": location,
            "quantity": quantity,
            "branch": store.state.user.branch
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {makeStockAdjustment(lineNumber, location, quantity)}, 10000)
    })
}

function registerBarcode(item, barcode){
    axios({
        method: "POST",
        url: vars.apiURL + "items/" + item.lineNumber + "/barcode",
        data: {
            "lineNumber": item.lineNumber,
            "barcode": barcode.barcode,
            "quantity": barcode.quantity,
            "type": barcode.type
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {registerBarcode(item, barcode)}, 10000)
    })
}

function registerSubstitution(payload){
    axios({
        method: "POST",
        url: vars.apiURL + "items/" + payload.originalLineNumber + "/substitute",
        data: {
            "originalLineNumber": payload.originalLineNumber,
            "replacementLineNumber": payload.replacementLineNumber,
            "quantity": payload.quantity
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {registerSubstitution(payload)}, 10000)
    })
}

function quickPick(lineNumber, location, quantityToDecrease){
    axios({
        method: "PATCH",
        url: vars.apiURL + "stock/quick-pick",
        data: {
            lineNumber,
            location,
            quantityToDecrease,
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {quickPick(lineNumber, location, quantityToDecrease)}, 10000)
    })
}

function returnStock(lineNumber, location, quantityToDecrease){
    axios({
        method: "PATCH",
        url: vars.apiURL + "stock/return",
        data: {
            lineNumber,
            location,
            quantityToDecrease,
        },
        headers: {
            "Authorization": "Bearer " + store.state.user.access_token
        }
    }).then(() => {
        store.commit("decreasePendingChanges")
    }).catch(() => {
        setTimeout(() => {returnStock(lineNumber, location, quantityToDecrease)}, 10000)
    })
}