<template>
<div>
    <v-row justify="center" style="margin-top: 5vh;">
        <v-col cols="8" align-self="center">
            <v-img src="@/assets/img/wap.png" />
        </v-col>
    </v-row>

    <v-row justify="center">
        <v-col cols="11">
            <GillTitle title="Welcome to Entertaining Stock Management"/>
        </v-col>
    </v-row>

    <v-row justify="center">
        <v-col cols="10">
            <v-text-field v-bind:disabled="loadLoginButton" type="number" label="Enter Partner Number" solo v-model="partnerNumber" />
            <WrButton v-bind:disabled="disableLoginButton" v-bind:loading="loadLoginButton" text="Login" v-on:login="login" />
        </v-col>
    </v-row>

    <v-row justify="center" class="version-info">
        <h2>v{{version}}</h2>
    </v-row>
    <v-row justify="center" class="version-info">
        <h2>{{release}}</h2>
    </v-row>

    <ConfirmDialog v-bind:dialog="showErrorDialog" v-on:closeDialog="showErrorDialog=false" title="Error" v-bind:text="errorDialogText"/>

</div>
</template>

<script>
// @ is an alias to /src
import GillTitle from "@/components/GillTitle.vue"
import WrButton from "@/components/WrButton.vue"
import ConfirmDialog from "@/components/WarningDialog.vue"

import vars from "@/assets/scripts/vars.js"

const axios = require("axios").default

export default {
    name: 'Home',
    data: () => {
        return {
            partnerNumber: null,
            loadLoginButton: false,
            showErrorDialog: false,
            errorDialogText: null
        }
    },
    components: {
        GillTitle,
        WrButton,
        ConfirmDialog
    },
    beforeMount() {
        this.$store.commit("setTitle", "Login")
    },
    methods: {
        login() {
            this.loadLoginButton = true

            axios.post(vars.apiURL + "users/login", {
                "username": parseInt(this.partnerNumber),
                "password": "password"
            }).then((res) => {
                this.$store.commit("setUser", res.data)
                this.$router.push("welcome")
            }).catch((err) => {
                if(typeof err.response != "undefined" && err.response.status == 401){
                    this.errorDialogText = "Partner not found. Check the number and try again."
                }else{
                    this.errorDialogText = "Unable to login. Please check your connection and try again."
                }
                this.showErrorDialog = true
            }).finally(() => {
                this.loadLoginButton = false
            })
        }
    },
    computed: {
        disableLoginButton: function() {
            return this.partnerNumber == null || this.loadLoginButton || this.partnerNumber.length != 8
        },
        version: function(){
            return vars.version
        },
        serverVersion: function(){
            return vars.serverVersion
        },
        release: function(){
            return vars.release
        }
    }
}
</script>
