<template>
    <div>
        <v-row justify="center">
            <v-col cols="6" class="" justify="center">
                <v-img v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + lineNumber.toString().padStart(6, '000000') + '_BP_11.jpg'" />
            </v-col>
            <v-col cols="6" class="" justify="center">
                <h1 id="title">{{title}}</h1>
            </v-col>
        </v-row>

        <v-row justify="center">
            <v-col cols="6" class="pl-6 pr-6 pt-0" justify="center">
                <h2>{{mass}}</h2>
            </v-col>
            <v-col cols="6" class="pl-6 pr-6 pt-0" justify="center">
                <h3>{{lineNumber}}</h3>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import utils from "@/assets/scripts/utils.js"

export default {
    name: 'Item',
    props: {
        lineNumber: Number,
    },
    data: function (){
        return {
            "title": "",
            "mass": 0
        }
    },
    created(){
        var item = utils.itemLookupService(this.lineNumber)
        this.title = item.title
        this.mass = item.mass
    },
    watch: {
        lineNumber: function(){
            var item = utils.itemLookupService(this.lineNumber)
            if(item != null){
                this.title = item.title
                this.mass = item.mass
            }else{
                this.title = ""
                this.mass = 0
            }
        }
    },
    computed: {

    },
    components: {

    },
    methods: {

    }
}
</script>

<style scoped>
    #title {
        font-size: 4vh;
    }
</style>
