<template>
    <h1 style="font-weight:400;">{{title}}</h1>
</template>

<script>
export default {
    name: 'GillTitle',
    props: {
        title: String
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    h1 {
        text-align: left;
        font-size: 4vh;
    }
</style>
