<template>
  <v-app-bar id="title-bg" fixed>
      <h1 style="font-weight:400;" id="titlebar-title">{{title}}</h1>
  </v-app-bar>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#title-bg{
    background-color: #154734;
    color: #FFFFFF;
    font-family: 'Gill Sans', sans-serif;
    font-style: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    font-size: 2vh;
    margin-top: 0px;
}
h1 {
    margin-top: 0px;
}
</style>
