<template>
    <v-card class="mt-6" id="options">
        <v-row justify="center">
            <v-col cols="9" align-self="center">
                <v-row justify="center" class="mt-3 mb-3">
                    <BSMButton v-on:click="manuallyAction" text="Manually Action" color="#F0D909" textColor="#000000" />
                </v-row>
                <v-row justify="center" class="mt-3 mb-3">
                    <BSMButton text="Missing" color="#E74C3C" textColor="#FFFFFF" v-on:click="confirmOffsale()" />
                </v-row>
            </v-col>
        </v-row>

        <WarningDialog v-bind:dialog="showConfirmDialog" title="Offsale"
            text="Find an appropriate substitution for this item if possible, or goodwill the customer." />
    </v-card>
</template>

<script>
import BSMButton from "@/components/BSMButton.vue"
import WarningDialog from "@/components/WarningDialog.vue"

export default {
    name: 'PickItemOptions',
    components: {
        BSMButton,
        WarningDialog
    },
    methods: {
        confirmOffsale: function () {
            this.showConfirmDialog = true
        },
        offsale: function () {
            this.manuallyAction()
        },
        manuallyAction: function () {
            if(!this.disable){
                this.pickFunction(this.pickListItem, 1, this.location, this.pickList)
            }
        }
    },
    props: {
        pickListItem: Object,
        location: String,
        disable: Boolean,
        pickFunction: Function,
        pickList: Object
    },
    data: function () {
        return {
            showConfirmDialog: false
        }
    }
}
</script>

<style scoped>
#options {
    background-color: #F1F1F1;
}
</style>
