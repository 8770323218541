<template>
    <div :class="{ good: this.type == 'good', warning: this.type == 'warning', bad: this.type == 'bad' }">
        <h1 id="icon">{{ getIcon }}</h1>
    </div>
</template>

<script>
export default {
    name: 'ScanAlert',
    props: {
        type: String,
    },
    data: function (){
        return {}
    },
    created(){

    },
    watch: {

    },
    computed: {
        getIcon() {
            return this.type == "good" ? "✓" : this.type == "warning" ? "!" : "X"
        }
    },
    methods: {

    },
    components: {

    }
}
</script>

<style scoped>
    div {
        position: absolute;
        top: -6vh;
        left: 0;
        width: 100%;
        height: calc(100% + 6vh);
        text-align: center;
        padding-top: auto;
        padding-bottom: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    #icon {
        font-size: 2500%;
        color: #FFFFFF;
    }

    .good {
        background-color: #4B9728
    }
    .warning {
        background-color: #FFA500;
    }
    
    .bad {
        background-color: #C72827;
    }
</style>
