<template>
    <div>
        <!--Line number entry for unknown items-->
        <v-row class="align-left mx-2 flex-column" v-if="enterLineNumber">
            <h1>Enter line number</h1>
            <p>This item is not known. Enter the line number to register as a substitution.</p>
            <v-text-field v-model="lineNumberEntry" label="Line number" type="number" min="1" max="999999" required />
            <v-btn class="py-12" color="green" v-on:click="enterLineNumberContinue">Continue</v-btn>
        </v-row>

        <!--Replacement quantity entry-->
        <v-row class="align-left mx-2 flex-column" v-if="enterReplacementQuantity">
            <h1>Enter replacement quantity</h1>
            <p>Enter the 'more or greater than' value. This will usually be 1. For instance, if you are substituting a
                500g item with 2x 250g items, enter '2'.</p>
            <v-text-field v-model="replacementQuantityEntry" label="Replacement quantity" type="number" min="1" max="99"
                required />
            <v-btn class="py-12" color="green" v-on:click="enterQuantity">Continue</v-btn>
        </v-row>

        <!--Scan putaway location-->
        <v-row class="align-left mx-2 flex-column" v-if="scanPutawayLocation">
            <h1>Scan putaway location</h1>
            <p>Scan the putaway location for the replacement items.</p>
            <img src="@/assets/img/ent-loc-scan.png" width="100%">
        </v-row>

        <v-row class="align-left mx-2 flex-column"
            v-if="(!enterLineNumber && !enterReplacementQuantity && !scanPutawayLocation)">
            <h1>Find a substitution</h1>
            <p>Scan any item to use as a substitution.</p>
        </v-row>

        <!--Suggestions-->
        <div v-if="(!enterLineNumber && !enterReplacementQuantity && !scanPutawayLocation)">
            <h2 class="mt-6">Previously used substitutions for this line</h2>
            <v-card v-for="item in availableSubs" v-bind:key="item.lineNumber" class="mb-5 mt-5 py-5 px-2">
                <v-row justify="center" class="d-flex">
                    <v-col cols="3" class="pb-0 pt-0 pl-3" align-self="center">
                        <v-img
                            v-bind:src="'https://ecom-su-static-prod.wtrecom.com/images/products/11/LN_' + String(item.replacementLineNumber).padStart(6, '0') + '_BP_11.jpg'" />
                    </v-col>
                    <v-col cols="9" class="pb-0 pr-0 pt-0 align-left" align-self="center">
                        <v-row>
                            <v-col cols="12" class="pb-2 pt-2 pr-0 pl-0">
                                <strong class="mb-title item-title text-h5">{{ getItemTitle(item.replacementLineNumber)
                                }}</strong>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="pb-0 pt-0 pr-0 pl-0">
                                <strong class="text-h5">{{ item.replacementLineNumber }}</strong>
                            </v-col>
                        </v-row>
                        <v-row class="mt-0">
                            <v-col cols="12" class="pt-0 pb-1 mt-2 pr-0 pl-0">
                                <small class="mb-desc text-h6">{{ item.quantity }} is equivalent to 1</small>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card>
        </div>

    </div>
</template>
    
<script>
import vars from "@/assets/scripts/vars.js"
import axios from "axios"

import utils from "@/assets/scripts/utils.js"

export default {
    name: 'PrepareSubstitutions',
    data: () => {
        return {
            availableSubs: [],
            enterLineNumber: false,
            scanPutawayLocation: false,
            replacementItem: null,
            lineNumberEntry: "",
            enterReplacementQuantity: false,
            replacementQuantityEntry: 1
        }
    },
    components: {

    },
    beforeMount() {
        this.$store.commit("setTitle", "Prepare Substitutions")
        this.$store.commit("enableNavbar", "true")
        this.$store.commit("setActionText", "")
    },
    mounted() {
        this.$store.commit("setLoading", true)
        this.orders = this.getAvailableSubs()
    },
    created() {
        document.addEventListener('keyInputXd', this.scanned)
    },
    destroyed() {
        document.removeEventListener('keyInputXd', this.scanned)
    },
    methods: {
        enterLineNumberContinue: function() {
            this.$store.commit("setLoading", true)
            //Do we know what this line is?
            if(Object.keys(this.$store.stock).includes(this.lineNumberEntry)){
                this.replacementItem = this.$store.stock[this.lineNumberEntry]
                this.enterReplacementQuantity = true
                this.enterLineNumber = false
                this.$store.commit("setLoading", false)
            }else{
                this.enterLineNumber = false
                this.enterReplacementQuantity = true
                //Tell the server to check this line out
                //Fetch from Waitrose.com
                axios.get(vars.apiURL + "items/" + this.$store.state.activeSubItem + "/substitutions", {
                    "headers": {
                        "Authorization": "Bearer " + this.$store.state.user.access_token
                    }
                }).then((response) => {
                    this.availableSubs = response.data
                    //Order subs by usage
                    this.availableSubs.sort((a, b) => {
                        return b.uses - a.uses
                    })
                    this.$store.commit("setLoading", false)
                }).catch((error) => {
                    console.log(error)
                })
            }
        },
        enterQuantity: function () {
            this.enterReplacementQuantity = false
            this.scanPutawayLocation = true
        },
        getAvailableSubs: function () {
            axios.get(vars.apiURL + "items/" + this.$store.state.activeSubItem + "/substitutions", {
                "headers": {
                    "Authorization": "Bearer " + this.$store.state.user.access_token
                }
            }).then((response) => {
                this.availableSubs = response.data
                //Order subs by usage
                this.availableSubs.sort((a, b) => {
                    return b.uses - a.uses
                })
                this.$store.commit("setLoading", false)
            }).catch((error) => {
                console.log(error)
            })
        },
        getItemTitle: function (lineNumber) {
            return this.$store.state.stock[lineNumber].title
        },
        findBestLocation: function () {
            return "A001A"
        },
        scanned: function (e) {
            if (this.$route.name == "FindSubstitution") {
                if (this.scanPutawayLocation) {
                    //User has scanned putaway location for the sub
                    if (e.detail.value.length == 5) {
                        let evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "good"
                            }
                        })
                        document.dispatchEvent(evnt)

                        //Receive item
                        this.$store.dispatch("adjustStock", {
                            "lineNumber": this.replacementItem.lineNumber,
                            "location": e.detail.value,
                            "quantity": parseInt(this.replacementQuantityEntry.toString())
                        })

                        //Register sub with server
                        this.$store.dispatch("registerSubstitution", {
                            "lineNumber": this.$store.state.activeSubItem,
                            "replacementLineNumber": this.replacementItem.lineNumber,
                            "quantity": parseInt(this.replacementQuantityEntry.toString())
                        })

                        this.scanPutawayLocation = false
                        this.replacementQuantityEntry = 1
                        this.lineNumberEntry = ""
                    } else {
                        //Not a valid location
                        let evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "bad"
                            }
                        })
                        document.dispatchEvent(evnt)
                    }
                } else {
                    let evnt = null
                    var item = utils.barcodeLookupService(e.detail.value)
                    if (item == null) {
                        //Item isn't known
                        this.newBarcode = e.detail.value
                        //Prompt for line number
                        this.enterLineNumber = true
                    } else {
                        //Barcode recognised
                        evnt = new CustomEvent("scanAlert", {
                            bubbles: false,
                            cancelable: false,
                            detail: {
                                type: "good"
                            }
                        })
                        document.dispatchEvent(evnt)

                        //Set replacement item
                        this.replacementItem = item

                        //Ask for replacement quantity
                        this.enterReplacementQuantity = true
                    }
                }
            }
        }
    },
    computed: {

    }
}
</script>
    