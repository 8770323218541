<template>
  <div>
    <!-- No active item -->
    <div v-if="activeItem === null">
      <!-- Line number entry mode -->
      <div>
        <GillTitle title="Enter a line number" />
        <v-text-field hide-details outlined v-model="lineNumberEntry" type="number" class="mb-5" id="lineNumberEntry" />
      </div>

      <!--Barcode mode -->
      <div v-if="!showUnknownLine">
        <GillTitle v-if="activeItem == null && !showUnknownLine" title="or scan/key an item" />
        <img src="@/assets/img/ent-scan.png" width="100%" style="margin-top: 3rem" />
      </div>
    </div>

    <!-- Active item -->
    <div v-if="activeItem != null">
      <Item v-bind:lineNumber="activeItem.lineNumber" />
      <v-row justify="center" class="mt-3">
        <v-col cols="10">
          <strong>Enter the quantity you want to pick from this location</strong>
          <BSMQuantity v-bind:quantity="quantity" v-on:setQty="setQty($event)" />
        </v-col>
      </v-row>

      <!-- Pick locations -->
      <div v-if="stockRecords.length !== 0">
        <!-- Most populated pick location  -->
        <v-row justify="center" align="center" class="mt-12" style="background-color: #ffff00">
          <h1 id="recommended-location">
            Pick location: <b>{{ stockRecords[0].locationValue }}</b> ({{ stockRecords[0].quantity }} available)
          </h1>
        </v-row>

        <!-- If more than one location, display the rest here -->
        <div v-if="stockRecords.length > 1">
          <v-row justify="center" align="center" class="mt-4">
            <h2>
              Other locations: <b>{{ stockRecords.slice(1).map(sr => `${sr.locationValue} (${sr.quantity})`).join(", ") }}</b>
            </h2>
          </v-row>
        </div>
      </div>
      <div v-else>
        <v-row justify="center" align="center" class="mt-12 red rounded white--text">
          <h1 id="recommended-location">
            No pick locations found for this item.
          </h1>
        </v-row>
      </div>
    </div>

    <WarningDialog v-bind:dialog="showLocationDialog" v-on:closeDialog="showLocationDialog = false"
      title="Scan a location"
      text="Not a valid putaway location. You must scan a location for this item or press 'Back'." />
  </div>
</template>

<style>
#recommended-location {
  font-size: 4vh;
}
</style>

<script>
// @ is an alias to /src

//import vars from "@/assets/scripts/vars.js"

//const axios = require("axios").default

import BSMQuantity from "@/components/BSMQuantity.vue";
import Item from "@/components/Item.vue";
import GillTitle from "@/components/GillTitle.vue";
import WarningDialog from "@/components/WarningDialog.vue";

import utils from "@/assets/scripts/utils.js";

export default {
  name: "QuickPick",
  data: () => {
    return {
      activeItem: null,
      quantity: 1,
      showLocationDialog: false,
      stockRecords: [],
      showUnknownLine: false,
      lineNumberEntry: "",
    };
  },
  components: {
    BSMQuantity,
    Item,
    GillTitle,
    WarningDialog,
  },
  beforeMount() {
    this.$store.commit("setTitle", "Ecom Pick");
    this.$store.commit("enableNavbar", "true");
    this.$store.commit("setActionText", "Scan/key an item");
  },
  created() {
    document.addEventListener("keyInputXd", this.scanned);
  },
  destroyed() {
    document.removeEventListener("keyInputXd", this.scanned);
  },
  watch: {
    lineNumberEntry: function () {
      var item = utils.itemLookupService(this.lineNumberEntry);
      if (item != null) {
        this.activeItem = item;
        this.quantity = 1;
        this.findPickLocations();
        this.showUnknownLine = false;
        this.$store.commit("setActionText", "Now scan pick location");
      }
    },
  },
  methods: {
    findPickLocations: function () {
      const stockEstimates = this.$store.state.stock[this.activeItem.lineNumber];
      this.stockRecords = stockEstimates.stockRecords.sort((a, b) => {
        return b.quantity - a.quantity;
      });
    },
    setQty: function (newQty) {
      this.quantity = newQty;
    },
    logOff: function () {
      this.$store.dispatch("logOff");
      this.$router.push("/");
    },
    scanned: function (e) {
      if (this.$route.name == "QuickPick") {
        let evnt = null;
        if (this.activeItem == null) {
          var item = utils.barcodeLookupService(e.detail.value);
          if (item == null) {

            this.showUnknownLine = true;
            evnt = new CustomEvent("scanAlert", {
              bubbles: false,
              cancelable: false,
              detail: {
                type: "warning",
              },
            });
            document.dispatchEvent(evnt);
          } else {
            evnt = new CustomEvent("scanAlert", {
              bubbles: false,
              cancelable: false,
              detail: {
                type: "good",
              },
            });
            document.dispatchEvent(evnt);

            this.showUnknownLine = false;
            this.activeItem = item;
            this.quantity = item.quantity;
            this.findPickLocations();
            if(this.stockRecords.length == 0){
              this.$store.commit("setActionText", "");
            }else{
              this.$store.commit("setActionText", "Now scan pick location");
            }
          }
        } else {
          //Should be a location
          if (e.detail.value.length == 5) {
            evnt = new CustomEvent("scanAlert", {
              bubbles: false,
              cancelable: false,
              detail: {
                type: "good",
              },
            });
            document.dispatchEvent(evnt);

            this.$store.dispatch("quickPick", {
              lineNumber: this.activeItem.lineNumber,
              location: e.detail.value,
              quantityToDecrease: this.quantity,
            });

            this.lineNumberEntry = "";
            this.activeItem = null;
            this.quantity = 1;
            this.stockRecords = [];
            this.$store.commit("setActionText", "Scan/key an item");
          } else {
            //Not a valid location
            this.showLocationDialog = true;
            evnt = new CustomEvent("scanAlert", {
              bubbles: false,
              cancelable: false,
              detail: {
                type: "bad",
              },
            });
            document.dispatchEvent(evnt);
          }
        }
      }
    },
  },
};
</script>
